import { applicationID, getViewStructure, getViewDataSet, relationId, userName } from '../../../api/ApplicationID';
import { useEffect, useState, useCallback, memo, useRef } from 'react';
import { httpRequest } from '../../../functions';
import { useStatePopup } from '../../../Context/StatePopupTabPanel';
import { usePopupView } from '../../../Context/PopupView';
import TabPanel from 'devextreme-react/tab-panel';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import {
    Popup,
    ToolbarItem,
} from 'devextreme-react/popup';
import { LoadPanel } from 'devextreme-react/load-panel';
import './style.css'
import { useIndexTabPanelMain } from '../../../Context/IndexTabPanelMain';
import { useTabPanelItems } from '../../../Context/TabPanelItems'
import ScrollView from 'devextreme-react/scroll-view';
import DataGrid, {
    Column,
    Selection,
    Summary,
    GroupItem,
    HeaderFilter,
    Sorting,
    SortByGroupSummaryInfo,
    Pager,
    Scrolling,
    GroupPanel,
    ColumnChooser,
    SearchPanel,
    Paging,
    FilterRow,
    FilterPanel,
    Export,
    TotalItem
} from 'devextreme-react/data-grid';
import Loading from '../../../CardComponents/Loading';
import RecordFormPopup from '../../RecordForm/RecordFormPopup';
import RecordForm from '../../RecordForm';
import ViewForm from ".."
import Accordion from 'devextreme-react/accordion';
import CheckBox from 'devextreme-react/check-box';
import { CommonSeriesSettingsHoverStyle } from 'devextreme-react/chart';
import { Toast } from 'devextreme-react/toast';
import { useRequestsApi } from "../../../Context/RequestsApi"
import PercentageBar from "./PercentageBar"
import PokeComponent from "./PokeComponent"
import { convertDate } from "../../../functions/convertDate"
import ViewFormPopup from "../ViewFormPopup"
import { SpeedDialAction } from 'devextreme-react';
import UpfHtmlEditor from '../../UpfComponents/UpfHtmlEditor';
import { formatFunction } from '../../../functions/formatFunction';
import ExportingExcel from '../../../CardComponents/ExportingExcel';
import { Button } from 'devextreme-react/button';
import LoadingData from '../../../CardComponents/LoadingData';
import UpfPopupConfirmContextMenu from '../../../CardComponents/UpfPopupConfirmContextMenu';
import { changeDataRowColor } from '../../../functions/changeDataRowColor';
import UpfAudio from '../../UpfComponents/UpfAudio';
import { calculateCustomSummary } from '../../../functions/calculateCustomSummary';
import { useTelecobTabPanelItemsGlobalState } from '../../../Context/TelecobTabPanelItemsGlobalState';
import { useProximaFichaDados } from '../../../Context/ProximaFichaDados';
import MultiViewMainTeleCob from '../../TeleCob/MultiViewMainTeleCob';
import { removerTagsHtml } from '../../../functions/removerTagsHtml'

const DataGridViewForm = (props) => {
    const { requestsApi } = useRequestsApi()
    const {telecobTabPanelItemsGlobalState, setTelecobTabPanelItemsGlobalState} = useTelecobTabPanelItemsGlobalState()
    const {proximaFichaDados, setProximaFichaDados} = useProximaFichaDados()


    const popups = {
        isVisible: false,
        message: 'Houve um erro',
    };
    const { setIndexTabPanelMain } = useIndexTabPanelMain()
    const { tabPanelItems, setTabPanelItems } = useTabPanelItems();
    const { setPopupView } = usePopupView()
    // const { viewResultData } = useViewResultData();
    // const [resultViewDataSet, setResultViewDataSet] = useState();
    const [popup, setPopup] = useState(popups);
    const [messageError, setMessageError] = useState()
    const [recordLotePopup, setRecordLotePopup] = useState(false);
    const [selectedItensCount, setSelectedItensCount] = useState(0)
    const [selectedItens, setSelectedItens] = useState()
    const [recordFormPopupRender, setRecordFormPopupRender] = useState(false)
    const allowedPageSizes = [10, 25, 50, 100];
    const [getViewStructure, setGetViewStructure] = useState(props.states?.getViewStructure)
    const convertNumberToCurrency = new Intl.NumberFormat('pt-BR',
        {
            style: 'currency',
            currency: 'BRL',
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
        }
    );

    const [visibleConfirmContextMenuState, setVisibleConfirmContextMenuState] = useState(false)
    const [contextMenuItemState, setContextMenuItemState] = useState()

    const hidePopupConfirmContextMenu = () => {
        setVisibleConfirmContextMenuState(false)
    }

    const _dataGridViewFormRef = useRef(null)

    const [loadPanelVisibleState, setLoadPanelVisibleState] = useState(false)

    const actionExportExcel = useCallback(() => {
        if (_dataGridViewFormRef) {
            // console.log(_dataGridViewFormRef)
            ExportingExcel(_dataGridViewFormRef, props.caption);
        }
    }, [])

    const saveExcelButtonOptions = {
        text: 'Exportar para Excel'
        , icon: 'exportxlsx'
        , onClick: actionExportExcel
    };

    const [states, setStates] = useState({
        resultViewStructure: [],
        toastVisible: false,
        toastType: "",
        toastMessage: "",
        loteId: "",
        cellDescription: null,
        cellDescriptionArray: []
    })

    const toastConfiguration = (visible, type, message) => {
        setStates({
            ...states,
            toastVisible: visible,
            toastType: type,
            toastMessage: message
        })
    }

    const onHidingToast = () => {
        setStates({ ...states, toastVisible: false })
    }
    const senderFieldNamesCheckBox = []

    useEffect(() => {
        const dataRequest = {
            "viewId": props.id
            , "applicationId": applicationID
        }

        //codigo pra mudar o valor do checkbox de null para false
        // console.log(props.resultViewDataSet)

        // for(let c = 0; c <= props.resultViewDataSet.length - 1; c++) {
        //     for(let i = 0; i <= senderFieldNamesCheckBox.length - 1; i++) {
        //         if(props.resultViewDataSet[c][`${senderFieldNamesCheckBox[i]}`] === null) {
        //             props.resultViewDataSet[c][`${senderFieldNamesCheckBox[i]}`] = false
        //         }
        //     }
        // }

        for(let c = 0; c <= props.resultViewDataSet.length - 1; c++) {
            if(props.resultViewDataSet[c]?._crypttexto) {
                props.resultViewDataSet[c]["_crypttexto"] = removerTagsHtml(props.resultViewDataSet[c]._crypttexto)
            }
        }

        console.log(props.resultViewDataSet)

        if (!props.states?.resultViewStructure) {
            httpRequest('POST', requestsApi.getViewStructure, requestsApi.basicAuth, dataRequest)
                .then(senderGetViewStructure => {
                    if (senderGetViewStructure === "<HTML><BODY><B>200 OK</B></BODY></HTML>" ||
                        Object.values(senderGetViewStructure).length === 0) {
                        setStates({ ...states, resultViewStructure: [] })
                    } else {
                        const resultGetViewScructure = JSON.parse(senderGetViewStructure)

                        if (props.cellDescriptionVisible) {
                            resultGetViewScructure[0].view.dataGridProperties.fieldsDefs.unshift({
                                "fieldName": "cellDescription",
                                "fieldCaption": "Célula",
                                "fieldType": "varchar",
                                "fieldSize": 500,
                                "fieldMask": null,
                                "fieldDisplayFormat": null,
                                "gridVisible": 1,
                                "groupIndex": 0,
                                "gridVisibleForCustomization": 0,
                                "colSpan": null
                            })
                        }
                        else {
                            resultGetViewScructure[0].view.dataGridProperties.fieldsDefs.unshift({
                                "fieldName": "cellDescription",
                                "fieldCaption": "Descrição da Celula",
                                "fieldType": "varchar",
                                "fieldSize": 500,
                                "fieldMask": null,
                                "fieldDisplayFormat": null,
                                "gridVisible": 0,
                                "gridVisibleForCustomization": 0,
                                "colSpan": null
                            })
                        }
                        convertDate(props.resultViewDataSet, resultGetViewScructure[0].view.dataGridProperties.fieldsDefs)

                        setStates({ ...states, resultViewStructure: resultGetViewScructure })
                    }
                })
        }
        else {
            convertDate(props.resultViewDataSet, props.states?.getViewStructure[0].view.dataGridProperties.fieldsDefs)
            setStates({ ...states, resultViewStructure: props.states?.getViewStructure })
        }
    }, [])

    // },[states.resultViewStructure])

    //Funções

    const hidePopup = useCallback(() => {
        setPopup({
            ...popup,
            isVisible: false,
        })
    }, [popup]);
    const closeButtonPopup = {
        text: 'Fechar'
        , icon: 'arrowright'
        , onClick: hidePopup
    };

    const onCellPreparedFunctionColor = (e) => {
        if (e.rowType === "data") {
            if (states.resultViewStructure[0].view.dataGridProperties.rowColor) {
                changeDataRowColor(e, states.resultViewStructure[0].view.dataGridProperties.rowColor)
            }
        }
    }

    const [dataSource, setDataSource] = useState()
    const [viewFormPopupVisible, setViewFormPopupVisible] = useState(false)

    const [viewFormPopupRecordFormStates, setViewFormPopupRecordFormStates] = useState({
        visible: false,
        entityStructure: "",
        entityName: "",
        idValue: "",
        dataLookup: "",
        title: ""
    })

    const hideviewFormPopupRecordForm = () => {
        setViewFormPopupRecordFormStates({...viewFormPopupRecordFormStates, visible: false})
    }

    const RenderViewFormPopup = memo(() => {
        return (
            <ViewFormPopup
                masterViewId={dataSource.masterViewId}
                caption={dataSource.caption}
                viewFieldId={dataSource.viewFieldId}
                viewFieldName={dataSource.viewFieldName}
                selectedItemsId={dataSource.selectedItemsId}
                setPopupState={setViewFormPopupVisible}
                popupState={viewFormPopupVisible}
                telecob={props.telecob}
            />
        );
    })

    const getSelectedItemsDataGrid = (itemMenu) => {
        let dataGirdSelectedRowsData = _dataGridViewFormRef.current.instance.getSelectedRowsData()
        setGetViewStructure(props.states?.getViewStructure)

        //talvez n precise
        // setSelectedItens(dataGirdSelectedRowsData)
        // props.selectedItems && props.selectedItems(dataGirdSelectedRowsData.length)
        // setSelectedItensCount(dataGirdSelectedRowsData.length)
        //---------------

        //chama função q abre o menu de contexto
        openMenuContextFunction(itemMenu, dataGirdSelectedRowsData)
    }

    const onSelectionChanged = (e) => e.component.refresh(true);

    const onClickMenuContextItem = (item) => {
        if(!item.itemData?.askConfirm){
            let dataGirdSelectedRowsData = _dataGridViewFormRef.current.instance.getSelectedRowsData()
            if (item.itemData.componentName === "recordForm") {

                httpRequest('POST', requestsApi.getEntityStructure, requestsApi.basicAuth, {
                    entityName: item.itemData.componentParameter,
                    applicationId: applicationID
                })
                    .then((sender) => {
                        const resultGetEntityStructureJson = JSON.parse(sender)
                        if (resultGetEntityStructureJson?.[0]?.lookUpEntities?.length > 0) {
                            !resultGetEntityStructureJson[0]?.lookUpEntities.find(e => e.lookUpCaption === "_funcionarios") && resultGetEntityStructureJson[0]?.lookUpEntities?.push({ lookUpCaption: "_funcionarios", lookUpEntity: "_funcionario" })
                            httpRequest("POST", requestsApi.getLookupTable, requestsApi.basicAuth, {
                                "lookUpEntities": resultGetEntityStructureJson?.[0]?.lookUpEntities
                            })
                                .then((sender) => {
                                    const senderDataLookup = JSON.parse(sender)

                                    setTabPanelItems([
                                        ...tabPanelItems,
                                        {
                                            title: `${item.itemData.componentParameter}`
                                            , icon: item.itemData.icon
                                            , component:
                                                <ScrollView width='100%' height='100%' useNative={true}>
                                                    <RecordForm
                                                        entityName={item.itemData.componentParameter}
                                                        idValue={dataGirdSelectedRowsData[0][`${item.itemData.viewFieldName}`]}
                                                        entityStructure={resultGetEntityStructureJson}
                                                        dataLookup={senderDataLookup}
                                                    />
                                                </ScrollView>
                                            , text: Math.random()
                                        }
                                    ])
                                    setIndexTabPanelMain(tabPanelItems.length)

                                })
                                .catch((error) => console.log(error))
                        }
                        else {
                            !resultGetEntityStructureJson[0]?.lookUpEntities.find(e => e.lookUpCaption === "_funcionarios") && resultGetEntityStructureJson[0]?.lookUpEntities?.push({ lookUpCaption: "_funcionarios", lookUpEntity: "_funcionario" })
                            setTabPanelItems([
                                ...tabPanelItems,
                                {
                                    title: `${item.itemData.componentParameter}`
                                    , icon: item.itemData.icon
                                    , component:
                                        <ScrollView width='100%' height='100%' useNative={true}>
                                            <RecordForm
                                                entityName={item.itemData.componentParameter}
                                                idValue={dataGirdSelectedRowsData[0][`${item.itemData.viewFieldName}`]}
                                                entityStructure={resultGetEntityStructureJson}
                                            />
                                        </ScrollView>
                                    , text: Math.random()
                                }
                            ])
                            setIndexTabPanelMain(tabPanelItems.length)

                        }
                    })
                    .catch((error) => console.log(error))
            }

            else if (item.itemData.componentName === "viewForm") {
                const viewFieldName = item.itemData.viewFieldName.toLowerCase()
                const masterViewId = item.itemData.componentParameter

                const selectedItemsId = []
                for (let c = 0; c <= dataGirdSelectedRowsData.length - 1; c++) {
                    selectedItemsId.push(dataGirdSelectedRowsData[c][`${viewFieldName}`])
                }

                console.log(item)
                console.log(selectedItemsId)
                console.log(dataGirdSelectedRowsData)
                console.log(viewFieldName)

                setDataSource({
                    masterViewId: masterViewId,
                    caption: item.itemData.text,
                    // viewFieldId: item.itemData[`${viewFieldName}`],
                    viewFieldId: selectedItemsId[0],
                    viewFieldName: viewFieldName,
                    selectedItemsId: selectedItemsId
                })
                setViewFormPopupVisible(true)
            }
            else if (item.itemData.methodName) {
                //no revolution porta 8080 n veio item.itemData
                //por isso foi criado o condicional (07/01/2022)
                let idValueJson = []
                for (let a = 0; a <= dataGirdSelectedRowsData.length - 1; a++) {
                    idValueJson.push(dataGirdSelectedRowsData[a][`${item.itemData.viewFieldName}`])
                }

                httpRequest('POST', `${requestsApi.customUrl}${item.itemData.methodName}`, requestsApi.basicAuth,
                    {
                        "applicationId": applicationID,
                        "relationId": relationId,
                        "idValue": idValueJson,
                        "parameter": item.itemData.componentParameter
                    })
                    .then((sender) => {
                        const response = JSON.parse(sender)
                        toastConfiguration(true, 'info', response.mensagem)

                    })
                    .catch((err) => {
                        console.log(err)
                        if(err?.data?.error){
                            setMessageError(err.data.error)
                        }
                        else{
                            setMessageError("Ocorreu um erro. Tente novamente mais tarde!")
                        }

                        setPopup({
                            ...popup
                            , isVisible: true
                            , message: "Atenção!"
                        })
                        
                    })

            }
            else {
                setMessageError("Não foi possível abrir o menu de contexto")
                setPopup({
                    ...popup
                    , isVisible: true
                    , message: "Atenção!"
                })
            }
        }
        else{
            setContextMenuItemState(item)
            setVisibleConfirmContextMenuState(true)
        }
    }

    const createRecordForm = () => {
        httpRequest('POST', requestsApi.getEntityStructure, requestsApi.basicAuth, {
            entityName: "_lote",
            applicationId: applicationID
        })
            .then((sender) => {
                const resultGetEntityStructureJson = JSON.parse(sender)

                const viewIdString = `viewsid='${props.id}'`
                const lookUpEntityViewsTipoProcessamento = resultGetEntityStructureJson[0]?.lookUpEntities.find(e => e.lookUpEntity === "viewstipoprocessamentolookup")
                const objectLookUpEntityViewsTipoProcessamento = {"lookUpEntity":"viewstipoprocessamentolookup","lookUpCaption":"Processamentos","filter": viewIdString}
                if(lookUpEntityViewsTipoProcessamento){
                    resultGetEntityStructureJson[0]?.lookUpEntities.splice(resultGetEntityStructureJson[0]?.lookUpEntities.indexOf(lookUpEntityViewsTipoProcessamento), 1, objectLookUpEntityViewsTipoProcessamento)
                }
                else{
                    resultGetEntityStructureJson[0]?.lookUpEntities?.push(objectLookUpEntityViewsTipoProcessamento)
                }

                if (resultGetEntityStructureJson?.[0]?.lookUpEntities?.length > 0) {
                    !resultGetEntityStructureJson[0]?.lookUpEntities.find(e => e.lookUpCaption === "_funcionarios") && resultGetEntityStructureJson[0]?.lookUpEntities?.push({ lookUpCaption: "_funcionarios", lookUpEntity: "_funcionario" })
                    httpRequest("POST", requestsApi.getLookupTable, requestsApi.basicAuth, {
                        "lookUpEntities": resultGetEntityStructureJson?.[0]?.lookUpEntities
                    })
                        .then((sender) => {
                            const senderDataLookup = JSON.parse(sender)

                            setTabPanelItems([
                                ...tabPanelItems,
                                {
                                    'title': "Lote selecionado"
                                    , 'component':
                                        <ScrollView width='100%' height='100%' useNative={true}>
                                            <RecordForm
                                                id={Math.random()}
                                                idValue={states.loteId}
                                                entityName="_lote"
                                                entityStructure={resultGetEntityStructureJson}
                                                dataLookup={senderDataLookup}
                                                objectLookUpEntityViewsTipoProcessamento={objectLookUpEntityViewsTipoProcessamento}
                                            />
                                        </ScrollView>
                                    , 'text': Math.random()
                                    , 'icon': 'edit'
                                }
                            ])
                            setIndexTabPanelMain(tabPanelItems.length)

                        })
                        .catch((error) => console.log(error))
                }
                else {
                    !resultGetEntityStructureJson[0]?.lookUpEntities.find(e => e.lookUpCaption === "_funcionarios") && resultGetEntityStructureJson[0]?.lookUpEntities?.push({ lookUpCaption: "_funcionarios", lookUpEntity: "_funcionario" })
                    setTabPanelItems([
                        ...tabPanelItems,
                        {
                            'title': "Lote selecionado"
                            , 'component':
                                <ScrollView width='100%' height='100%' useNative={true}>
                                    <RecordForm
                                        id={Math.random()}
                                        idValue={states.loteId}
                                        entityName="_lote"
                                        entityStructure={resultGetEntityStructureJson}
                                        objectLookUpEntityViewsTipoProcessamento={objectLookUpEntityViewsTipoProcessamento}
                                    />
                                </ScrollView>
                            , 'text': Math.random()
                            , 'icon': 'edit'
                        }
                    ])
                    setIndexTabPanelMain(tabPanelItems.length)
                }
            })
            .catch((error) => console.log(error))

    }

    // const onCellClick = (e) => {
    //     console.log(e)
    //     console.log("entrou no oncellclick")
    //     if (e.cell.rowPath && e.cell.columnPath){
    //         let rowPathName = ""
    //         for (let a = 0; a <= e.cell.rowPath.length - 1; a++){
    //             rowPathName = rowPathName + e.cell.rowPath[a]+" / "
    //         }
    //         // tirando a ultima barrinha
    //         rowPathName = rowPathName.substring(0,rowPathName.length-2)

    //         let columnPathName = ""
    //         for (let a = 0; a <= e.cell.columnPath.length - 1; a++){
    //             columnPathName = columnPathName + e.cell.columnPath[a]+" / "
    //         }
    //         columnPathName = columnPathName.substring(0,columnPathName.length-2)
    //         states.cellDescription = `${columnPathName} X ${rowPathName}`
    //     }
    // }
    const openBatch = (itensSelecionados) => {
        console.log("openlote")

        console.log(itensSelecionados)

        //estado carregando true mostrando um load menor e
        // desativando o botão 
        // setStates({...states, loadIndicatorOpenBatchVisible: true})

        let observacoes = ""

        //essas linhas pegam a data e hora no momento que chama a função
        let stringDate = new Date().toLocaleString(undefined, { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', hour12: false, minute: '2-digit', second: '2-digit' })
        let newStringDate = new Date().toISOString().slice(0, 19).replace('T', ' ')

        //junta o formato da data da segunda linha com a hora da primeira
        let stringDateResult = newStringDate.slice(0, 10).concat(stringDate.slice(11, 20))
        let json = {
            _descricao: `Lote aberto por ${userName}`,
            _observacoes: `${observacoes}`,
            _liberado: 1,
            _data: stringDateResult,
            _funcionarioId: relationId
        }

        setLoadPanelVisibleState(true)

        httpRequest("POST",
            requestsApi.insertRecordFromJson, requestsApi.basicAuth,
            {
                "entityName": "_lote",
                "applicationId": applicationID,
                "relationId": relationId,
                "json": json
            })
            .then((sender) => {
                states.loteId = JSON.parse(sender).idValue
                let jsonLoteItem = []
                for (let b = 0; b <= itensSelecionados.length - 1; b++) {
                    jsonLoteItem.push({
                        "_loteId": states.loteId,
                        _tituloId: itensSelecionados[b]._tituloid,
                        _objectId: itensSelecionados[b]?._objectid
                    })
                }

                httpRequest("POST",
                    requestsApi.insertRecordFromJson, requestsApi.basicAuth,
                    {
                        "entityName": "_loteItem",
                        "json": jsonLoteItem,
                        "applicationId": applicationID,
                        "relationId": relationId
                    })
                    .then((sender) => {
                        toastConfiguration(true, "success", "Lote gravado")
                        //trocar estado do componente para false
                        // setStates({...states, loadIndicatorOpenBatchVisible: true})
                        // clearDrillDownDataSource()
                        //vai precisar abrir o record form em outra aba
                        setLoadPanelVisibleState(false)
                        createRecordForm()
                    })
                    .catch((error) => {
                        setLoadPanelVisibleState(false)
                        if (error.data.error) {
                            // hideLoadPanel()
                            setMessageError(error.data.error)
                            setPopup({
                                ...popup
                                , isVisible: true
                                , message: error.data.error
                            })
                        }
                    })

            })
            .catch((error) => {
                console.log(error.data.error)
                setLoadPanelVisibleState(false)

                // hideLoadPanel()
                setMessageError(`${error.data.error}`)
                setPopup({
                    ...popup
                    , isVisible: true
                    , message: "Atenção!"
                })
            })
    }
    const openMenuContextFunction = (itemMenu, itensSelecionados) => {
        console.log("openMenuContextFunction")

        console.log(itensSelecionados)

        const setItensSelecionadosSemRepetidos = new Set();

        const filterItensSelecionadosSemRepetidos = itensSelecionados.filter((e) => {
            if(e?._objectid){
                const duplicateditensSelecionadosSemRepetidos = setItensSelecionadosSemRepetidos.has(e._objectid);
                setItensSelecionadosSemRepetidos.add(e._objectid);
                return !duplicateditensSelecionadosSemRepetidos;
            }
            else if(e?._tituloid){
                const duplicateditensSelecionadosSemRepetidos = setItensSelecionadosSemRepetidos.has(e._tituloid);
                setItensSelecionadosSemRepetidos.add(e._tituloid);
                return !duplicateditensSelecionadosSemRepetidos;
            }
        });

        console.log(setItensSelecionadosSemRepetidos)

        console.log(filterItensSelecionadosSemRepetidos)

        let abrirLoteRegistrosSelecionados = false
        
        for (let c = 0; c <= states.resultViewStructure[0].view.dataGridProperties.fieldsDefs.length - 1; c++) {
            console.log(states.resultViewStructure[0].view.dataGridProperties.fieldsDefs[c]["fieldName"])
            if (states.resultViewStructure[0].view.dataGridProperties.fieldsDefs[c]["fieldName"] === "_tituloid" || states.resultViewStructure[0].view.dataGridProperties.fieldsDefs[c]["fieldName"] === "_objectid") {
                abrirLoteRegistrosSelecionados = true
                console.log(states.resultViewStructure[0].view.dataGridProperties.fieldsDefs[c]["fieldName"])
            }
        }
        let menuContextArray = []

        console.log(props)

        if (abrirLoteRegistrosSelecionados && !props.telecob) {
            menuContextArray = [
                {
                    text: "Abrir lote para os registros selecionados"
                    , icon: "plus"
                    , onItemClick: () => { openBatch(filterItensSelecionadosSemRepetidos) }
                }
            ]
        }
        console.log(menuContextArray)
        // else {
        //     menuContextArray = [
        //         {
        //             text: "Abrir lote para os registros selecionados"
        //             ,icon: "plus"
        //             ,disabled: true
        //             ,onItemClick: openBatch
        //         }
        //     ]
        // }
        if (states.resultViewStructure[0].view.contextMenu !== null) {
            for (let c = 0; c <= states.resultViewStructure[0].view.contextMenu.length - 1; c++) {
                let contextMenuItem = {
                    "text": states.resultViewStructure[0].view.contextMenu[c].caption,
                    "icon": states.resultViewStructure[0].view.contextMenu[c].icon,
                    "onItemClick": onClickMenuContextItem,
                    "componentName": states.resultViewStructure[0].view.contextMenu[c].componentName,
                    "componentParameter": states.resultViewStructure[0].view.contextMenu[c].componentParameter,
                    "viewFieldName": states.resultViewStructure[0].view.contextMenu[c].viewFieldName,
                    "methodName": states.resultViewStructure[0].view.contextMenu[c].methodName,
                    "askConfirm": states.resultViewStructure[0].view.contextMenu[c].askConfirm
                }
                menuContextArray.push(contextMenuItem)
            }
            console.log(itemMenu)
            if (itemMenu.target === "content") {
                if (itemMenu.row) {
                    if (itemMenu.row.rowType === "data") {
                        itemMenu.items = menuContextArray

                        // itemMenu.items = [{
                        //     text: "Editar",
                        //     onItemClick: function () {
                        //         itemMenu.component.editRow(itemMenu.row.rowIndex);
                        //     }
                        // },
                        // {
                        //     text: "Inserir",
                        //     onItemClick: function () {
                        //         itemMenu.component.addRow();
                        //     }
                        // },
                        // {
                        //     text: "Deletar",
                        //     onItemClick: function () {
                        //         // consolitemMenu.log(itemMenu.component)
                        //         // consolitemMenu.log(itemMenu.component.getSelectedRowKeys())
                        //         // consolitemMenu.log( itemMenu.component.getSelectedRowsData())
                        //         itemMenu.component.deleteRow(itemMenu.rowIndex)
                        //         // httpRequest('POST', deleteRecordFromJson, requestsApi.basicAuth, {
                        //         //     teste
                        //         // }).then((response=>{
                        //         //     const resultDelete = JSON.parse(response);

                        //         // }))
                        //         // setMasterEntity("")
                        //         // consolitemMenu.log(itemMenu.component.getRowIndexByKey(teste))
                        //         // itemMenu.component.refresh()
                        //     }
                        // },
                        // {
                        //     text: "Abrir Lote"
                        //     ,onItemClick: ()=> {
                        //         setPopupView(true)
                        //         setRecordFormPopupRender(true)
                        //     }
                        // }];
                    }
                }
            }
        }
        else {
            if (itemMenu.target === "content" && filterItensSelecionadosSemRepetidos?.length > 0) {
                if (itemMenu.row) {
                    if (itemMenu.row.rowType === "data") {
                        itemMenu.items = menuContextArray

                        // itemMenu.items = [{
                        //     text: "Editar",
                        //     onItemClick: function () {
                        //         itemMenu.component.editRow(itemMenu.row.rowIndex);
                        //     }
                        // },
                        // {
                        //     text: "Inserir",
                        //     onItemClick: function () {
                        //         itemMenu.component.addRow();
                        //     }
                        // },
                        // {
                        //     text: "Deletar",
                        //     onItemClick: function () {
                        //         // consolitemMenu.log(itemMenu.component)
                        //         // consolitemMenu.log(itemMenu.component.getSelectedRowKeys())
                        //         // consolitemMenu.log( itemMenu.component.getSelectedRowsData())
                        //         itemMenu.component.deleteRow(itemMenu.rowIndex)
                        //         // httpRequest('POST', deleteRecordFromJson, requestsApi.basicAuth, {
                        //         //     teste
                        //         // }).then((response=>{
                        //         //     const resultDelete = JSON.parse(response);

                        //         // }))
                        //         // setMasterEntity("")
                        //         // consolitemMenu.log(itemMenu.component.getRowIndexByKey(teste))
                        //         // itemMenu.component.refresh()
                        //     }
                        // },
                        // {
                        //     text: "Abrir Lote"
                        //     ,onItemClick: ()=> {
                        //         setPopupView(true)
                        //         setRecordFormPopupRender(true)
                        //     }
                        // }];
                    }
                }
            }
        }
    }

    const rowDblClickOpenFormFunction = (item) => {
        console.log(item.data)
        const fieldNameId = states.resultViewStructure[0].view.dataGridProperties.onDblClick.viewFieldName.toLowerCase()
        const entityNameView = states.resultViewStructure[0].view.dataGridProperties.onDblClick.componentParameter
        const titleView = states.resultViewStructure[0].view.dataGridProperties.onDblClick.componentCaption

        if (states.resultViewStructure[0].view.dataGridProperties.onDblClick.componentName !== null
            && states.resultViewStructure[0].view.dataGridProperties.onDblClick.componentName === 'recordForm') {
            httpRequest('POST', requestsApi.getEntityStructure, requestsApi.basicAuth, {
                entityName: entityNameView,
                applicationId: applicationID
            })
                .then((sender) => {
                    const resultGetEntityStructureJson = JSON.parse(sender)

                    if (resultGetEntityStructureJson?.[0]?.lookUpEntities?.length > 0) {
                        !resultGetEntityStructureJson[0]?.lookUpEntities.find(e => e.lookUpCaption === "_funcionarios") && resultGetEntityStructureJson[0]?.lookUpEntities?.push({ lookUpCaption: "_funcionarios", lookUpEntity: "_funcionario" })
                        httpRequest("POST", requestsApi.getLookupTable, requestsApi.basicAuth, {
                            "lookUpEntities": resultGetEntityStructureJson?.[0]?.lookUpEntities
                        })
                            .then((sender) => {
                                const senderDataLookup = JSON.parse(sender)

                                if(props.telecob){
                                    sessionStorage.setItem("idTabSelecionada", telecobTabPanelItemsGlobalState.length)
                                    setTelecobTabPanelItemsGlobalState([...telecobTabPanelItemsGlobalState,
                                        {
                                            title: `${titleView}`
                                            // ,icon: itemClicado.itemData.icon
                                            , id : telecobTabPanelItemsGlobalState.length
                                            , component:
                                                <ScrollView width='100%' height='100%' useNative={true}>
                                                    <MultiViewMainTeleCob 
                                                        className="tabpanel-main-telecob"
                                                        idTab={telecobTabPanelItemsGlobalState.length}
                                                        // entityStructure={resultGetEntityStructureJson}
                                                        // entityName={entityNameView}
                                                        idValue={item.data[`${fieldNameId}`]}
                                                        // dataLookup={senderDataLookup}
                                                        dataSource={item.data}
                                                        abrirFicha={props.abrirFicha}
                                                    />
                                                </ScrollView>  
                                            , text: Math.random()
                                        }
                                    ])
                                    setProximaFichaDados({...proximaFichaDados,
                                        tituloId: item.data[`${fieldNameId}`],
                                        credorId: null,
                                        clienteId: null,
                                        dataEntityCadastro: {},
                                        nomeFilaAtual: "",
                                        nomeRegraAtual: ""
                                    })
                                }
                                else{
                                    setTabPanelItems([
                                        ...tabPanelItems,
                                        {
                                            title: `${titleView}`
                                            // ,icon: itemClicado.itemData.icon
                                            , component:
                                                <ScrollView width='100%' height='100%' useNative={true}>
                                                    <RecordForm 
                                                        entityStructure={resultGetEntityStructureJson}
                                                        entityName={entityNameView}
                                                        idValue={item.data[`${fieldNameId}`]}
                                                        dataLookup={senderDataLookup}
                                                    />
                                                </ScrollView>
                                            , text: Math.random()
                                        }
                                    ])
                                    setIndexTabPanelMain(tabPanelItems.length)
                                }
                                

                            })
                            .catch((error) => console.log(error))
                    }
                    else {
                        !resultGetEntityStructureJson[0]?.lookUpEntities.find(e => e.lookUpCaption === "_funcionarios") && resultGetEntityStructureJson[0]?.lookUpEntities?.push({ lookUpCaption: "_funcionarios", lookUpEntity: "_funcionario" })
                        
                        if(props.telecob){
                            sessionStorage.setItem("idTabSelecionada", telecobTabPanelItemsGlobalState.length)
                            setTelecobTabPanelItemsGlobalState([...telecobTabPanelItemsGlobalState,
                                {
                                    title: `${titleView}`
                                    // ,icon: itemClicado.itemData.icon
                                    , id : telecobTabPanelItemsGlobalState.length
                                    , component:
                                        <ScrollView width='100%' height='100%' useNative={true}>
                                           <MultiViewMainTeleCob 
                                                className="tabpanel-main-telecob"
                                                // entityStructure={resultGetEntityStructureJson}
                                                // entityName={entityNameView}
                                                idValue={item.data[`${fieldNameId}`]}
                                                dataSource={item.data}
                                                abrirFicha={props.abrirFicha}
                                                idTab={telecobTabPanelItemsGlobalState.length}
                                            />
                                        </ScrollView>
                                    , text: Math.random()
                                }
                            ])
                        }
                        else{
                            setTabPanelItems([
                                ...tabPanelItems,
                                {
                                    title: `${titleView}`
                                    // ,icon: itemClicado.itemData.icon
                                    , component:
                                        <ScrollView width='100%' height='100%' useNative={true}>
                                            <RecordForm
                                                entityStructure={resultGetEntityStructureJson}
                                                entityName={entityNameView}
                                                idValue={item.data[`${fieldNameId}`]}
                                            />
                                        </ScrollView>
                                    , text: Math.random()
                                }
                            ])
                            setIndexTabPanelMain(tabPanelItems.length)
                        }
                        
                    }
                })
                .catch((error) => console.log(error))
        }
        else if(states.resultViewStructure[0].view.dataGridProperties.onDblClick.componentName !== null
            && states.resultViewStructure[0].view.dataGridProperties.onDblClick.componentName === 'recordFormPopup') {
            httpRequest('POST', requestsApi.getEntityStructure, requestsApi.basicAuth, {
                entityName: entityNameView,
                applicationId: applicationID
            })
                .then((sender) => {
                    const resultGetEntityStructureJson = JSON.parse(sender)

                    if (resultGetEntityStructureJson?.[0]?.lookUpEntities?.length > 0) {
                        !resultGetEntityStructureJson[0]?.lookUpEntities.find(e => e.lookUpCaption === "_funcionarios") && resultGetEntityStructureJson[0]?.lookUpEntities?.push({ lookUpCaption: "_funcionarios", lookUpEntity: "_funcionario" })
                        httpRequest("POST", requestsApi.getLookupTable, requestsApi.basicAuth, {
                            "lookUpEntities": resultGetEntityStructureJson?.[0]?.lookUpEntities
                        })
                            .then((sender) => {
                                const senderDataLookup = JSON.parse(sender)

                                setViewFormPopupRecordFormStates({
                                    visible: true,
                                    entityStructure: resultGetEntityStructureJson,
                                    entityName: entityNameView,
                                    idValue: item.data[`${fieldNameId}`],
                                    dataLookup: senderDataLookup,
                                    // title: `${titleView}`
                                })
                                // setTabPanelItems([
                                //     ...tabPanelItems,
                                //     {
                                //         title: `${titleView}`
                                //         // ,icon: itemClicado.itemData.icon
                                //         , component:
                                //             <ScrollView width='100%' height='100%'>
                                //                 <RecordForm
                                //                     entityStructure={resultGetEntityStructureJson}
                                //                     entityName={entityNameView}
                                //                     idValue={item.data[`${fieldNameId}`]}
                                //                     dataLookup={senderDataLookup}
                                //                 />
                                //             </ScrollView>
                                //         , text: Math.random()
                                //     }
                                // ])
                                // setIndexTabPanelMain(tabPanelItems.length)

                            })
                            .catch((error) => console.log(error))
                    }
                    else {
                        !resultGetEntityStructureJson[0]?.lookUpEntities.find(e => e.lookUpCaption === "_funcionarios") && resultGetEntityStructureJson[0]?.lookUpEntities?.push({ lookUpCaption: "_funcionarios", lookUpEntity: "_funcionario" })
                        setViewFormPopupRecordFormStates({
                            visible: true,
                            entityStructure: resultGetEntityStructureJson,
                            entityName: entityNameView,
                            idValue: item.data[`${fieldNameId}`],
                            // title: `${titleView}`
                        })
                        // setTabPanelItems([
                        //     ...tabPanelItems,
                        //     {
                        //         title: `${titleView}`
                        //         // ,icon: itemClicado.itemData.icon
                        //         , component:
                        //             <ScrollView width='100%' height='100%'>
                        //                 <RecordForm
                        //                     entityStructure={resultGetEntityStructureJson}
                        //                     entityName={entityNameView}
                        //                     idValue={item.data[`${fieldNameId}`]}
                        //                 />
                        //             </ScrollView>
                        //         , text: Math.random()
                        //     }
                        // ])
                        // setIndexTabPanelMain(tabPanelItems.length)
                    }
                })
                .catch((error) => console.log(error))
        }
        else if (states.resultViewStructure[0].view.dataGridProperties.onDblClick.componentName !== null
            && states.resultViewStructure[0].view.dataGridProperties.onDblClick.componentName === 'viewForm') {
            let viewFieldName = states.resultViewStructure[0].view.dataGridProperties.onDblClick.viewFieldName.toLowerCase()
            let masterViewId = states.resultViewStructure[0].view.dataGridProperties.onDblClick.componentParameter

            if (states.resultViewStructure[0].view.dataGridProperties.onDblClick.componentParameter) {
                setTabPanelItems([
                    ...tabPanelItems,
                    {
                        title: `${item.data.caption}`
                        // ,icon: itemClicado.itemData.icon
                        , component:
                            <ScrollView width='100%' height='100%' useNative={true}>
                                <ViewForm
                                    id={masterViewId}//id={item.data[`${viewFieldName}`]}
                                    caption={item.data.caption}
                                    viewFieldId={item.data[`${viewFieldName}`]}
                                    viewFieldName={viewFieldName}
                                    telecob={props.telecob}
                                />
                            </ScrollView>
                        , text: Math.random()
                    }
                ])
                setIndexTabPanelMain(tabPanelItems.length)
            } 
            else {
                setTabPanelItems([
                    ...tabPanelItems,
                    {
                        title: `${item.data.caption}`
                        // ,icon: itemClicado.itemData.icon
                        , component:
                            <ScrollView width='100%' height='100%' useNative={true}>
                                <ViewForm
                                    id={item.data[`${viewFieldName}`]}
                                    caption={item.data.caption}
                                    telecob={props.telecob}
                                />
                            </ScrollView>
                        , text: Math.random()
                    }
                ])
                setIndexTabPanelMain(tabPanelItems.length)
            }

        }
    }


    return (
        <div style={{ paddingBottom: "1.5em" }}>

            {viewFormPopupVisible &&
                <RenderViewFormPopup
                    key={Math.random()}
                    entityName="_lote"
                />
            }

            {viewFormPopupRecordFormStates.visible &&
                <Popup
                    visible={viewFormPopupRecordFormStates.visible}
                    onHiding={hideviewFormPopupRecordForm}
                    dragEnabled={false}
                    fullScreen={true}
                    hideOnOutsideClick={true}
                    showCloseButton={true}
                    showTitle={true}
                    // title={viewFormPopupRecordFormStates.title}
                    resizeEnabled={true}
                >
                    <ScrollView width='100%' height='100%' useNative={true}>
                        <RecordForm
                            entityStructure={viewFormPopupRecordFormStates.entityStructure}
                            entityName={viewFormPopupRecordFormStates.entityName}
                            idValue={viewFormPopupRecordFormStates.idValue}
                            dataLookup={viewFormPopupRecordFormStates.dataLookup}
                            popupRecordForm={true}
                        />
                    </ScrollView>
                </Popup>
            }

            {visibleConfirmContextMenuState &&
                <UpfPopupConfirmContextMenu
                    hidePopupConfirmContextMenu={hidePopupConfirmContextMenu}
                    visibleConfirmContextMenu={visibleConfirmContextMenuState}
                    contextMenuFunction={onClickMenuContextItem}
                    contextMenuItemState={contextMenuItemState}
                />
            }

            {states.resultViewStructure ? (
                <div>



                    {states.resultViewStructure.length > 0 &&
                        <>
                            {(props.states?.getViewStructure && props.resultViewDataSet) && props.states?.getViewStructure[0].view.contextMenu?.map((val, ind) => {
                                console.log(val)
                                return (props.selectItems !== 0 && val.actionButton !== null) ? (
                                    <SpeedDialAction
                                        icon={val.icon}
                                        label={val.caption}
                                        index={ind + 1}
                                        componentName={states.resultViewStructure[0].view.contextMenu[ind].componentName}
                                        visible={true}
                                        onClick={(e) => {
                                            console.log(e.event);
                                            let contextMenuItem = {
                                                itemData: {
                                                    "text": states.resultViewStructure[0].view.contextMenu[ind].caption,
                                                    "icon": states.resultViewStructure[0].view.contextMenu[ind].icon,
                                                    "onItemClick": onClickMenuContextItem,
                                                    "componentName": states.resultViewStructure[0].view.contextMenu[ind].componentName,
                                                    "componentParameter": states.resultViewStructure[0].view.contextMenu[ind].componentParameter,
                                                    "viewFieldName": states.resultViewStructure[0].view.contextMenu[ind].viewFieldName,
                                                    "methodName": states.resultViewStructure[0].view.contextMenu[ind].methodName
                                                }
                                            }
                                            onClickMenuContextItem(contextMenuItem)
                                        }} />
                                ) : (<></>)
                            }
                            )}
                            {/* <LoadPanel                        
                                showIndicator={loadPanelVisibleState}
                                visible={loadPanelVisibleState}
                                // shading={loadingPanelState.shading}
                                showPanel={loadPanelVisibleState}
                            /> */}
                            <Popup
                                visible={popup.isVisible}
                                onHiding={hidePopup}
                                dragEnabled={false}
                                hideOnOutsideClick={true}
                                showCloseButton={false}
                                showTitle={true}
                                title={popup.message}
                                width={300}
                                height={280}
                                resizeEnabled={true}
                            >
                                <ToolbarItem
                                    widget="dxButton"
                                    toolbar="bottom"
                                    location="after"
                                    options={closeButtonPopup}
                                />
                                {messageError &&
                                    <p>{messageError}</p>
                                }
                            </Popup>
                            <Toast
                                visible={states.toastVisible}
                                message={states.toastMessage}
                                type={states.toastType}
                                onHiding={onHidingToast}
                                displayTime={1800}
                                height={60}
                            />
                            <div className="div-gridResultView">
                                {/* {console.log(states.resultViewStructure[0].view.dataGridProperties.fieldsDefs[1].fieldName)} */}
                                {states.resultViewStructure[0].view.dataGridProperties && (
                                    <div>

                                        <TabPanel>
                                            <Item
                                                title={"Resultado da consulta"}

                                            >

                                                <div className="div-grid">
                                                    {loadPanelVisibleState &&

                                                        <div style={{padding: "1.5em"}}>
                                                                <LoadingData
                                                                    text={"Criando lote..."}
                                                                />
                                                        </div>
                                                    }

                                                    <DataGrid
                                                        ref={_dataGridViewFormRef}
                                                        keyExpr="keyexpr"
                                                        className="datagrid-view"
                                                        dataSource={props.resultViewDataSet.length > 0 ? props.resultViewDataSet : []}
                                                        columnAutoWidth={true}
                                                        showBorders={states.resultViewStructure[0].view.dataGridProperties.showGridBorders === 1}
                                                        rowAlternationEnabled={states.resultViewStructure[0].view.dataGridProperties.rowAlternationEnabled === 1}
                                                        repaintChangesOnly={true}
                                                        showColumnHeaders={states.resultViewStructure[0].view.dataGridProperties.showColumnHeaders === 1}
                                                        columnHidingEnabled={true}
                                                        columnMinWidth={50}
                                                        contextMenuEnabled={true}
                                                        columnResizingMode={"widget"}
                                                        allowColumnReordering={true}
                                                        focusedRowEnabled={true} //tava dando erro Row focusing requires the key field to be specified 
                                                        onCellPrepared={onCellPreparedFunctionColor}
                                                        onRowDblClick={rowDblClickOpenFormFunction}
                                                        onSelectionChanged={onSelectionChanged}
                                                        allowColumnResizing={true}
                                                        // onSelectionChanged={(sender) => {
                                                        //     setSelectedItensCount(sender.component.getSelectedRowsData().length)
                                                        //     setSelectedItens(sender.component.getSelectedRowsData())
                                                        //     props.selectedItems && props.selectedItems(sender.component.getSelectedRowsData().length)
                                                        //     setGetViewStructure(props.states?.getViewStructure)
                                                        // }}
                                                        onContextMenuPreparing={getSelectedItemsDataGrid}
                                                        onExporting={actionExportExcel}
                                                    // remoteOperations={{ summary: false }}
                                                        onEditorPreparing={e => {                                                            
                                                            if (e.editorName === "dxCheckBox") {
                                                                e.editorOptions.value = e.value === null ? false : e.value;
                                                            }
                                                        }}
                                                    >

                                                        <ColumnChooser
                                                            enabled={states.resultViewStructure[0].view.dataGridProperties.columnChooserEnabled === 1} width={400} height={800} mode={"select"} allowSearch={true}
                                                        />
                                                        {/* <LoadPanel 
                                                            enabled={true} 
                                                           
                                                        /> */}

                                                        <Scrolling
                                                            rowRenderingMode='infinite'
                                                            showScrollbar='always'
                                                            useNative={true}
                                                        >
                                                        </Scrolling>

                                                        <Paging defaultPageSize={states.resultViewStructure[0].view.dataGridProperties.pagingDefaultPageSize} />

                                                        <Sorting
                                                            mode="multiple"
                                                        />
                                                        <GroupPanel
                                                            visible={states.resultViewStructure[0].view.dataGridProperties.groupPanelVisible === 1}
                                                            allowColumnDragging={true}
                                                        />
                                                        <Pager
                                                            visible={states.resultViewStructure[0].view.dataGridProperties.pagerVisible === 1}
                                                            allowedPageSizes={states?.resultViewStructure?.[0]?.view?.dataGridProperties?.pagingDefaultPageSize ? states?.resultViewStructure?.[0]?.view?.dataGridProperties?.pagingDefaultPageSize : 10}
                                                            showPageSizeSelector={true}
                                                            showInfo={true}
                                                            showNavigationButtons={true} />
                                                        <SearchPanel
                                                            visible={states.resultViewStructure[0].view.dataGridProperties.searchPanelVisible === 1}
                                                            placeholder="Buscar"
                                                            searchVisibleColumnsOnly={true}
                                                            highlightSearchText={true}
                                                            highlightCaseSensitive={false}
                                                        />
                                                        <FilterRow visible={states.resultViewStructure[0].view.dataGridProperties.filterRowVisible === 1} />
                                                        <FilterPanel visible={states.resultViewStructure[0].view.dataGridProperties.filterRowVisible === 1} />

                                                        <HeaderFilter
                                                            allowSearch={true}
                                                            visible={states.resultViewStructure[0].view.dataGridProperties.headerFilterVisible === 1}
                                                        />

                                                        <Selection
                                                            //keyExpr="keyExpr"
                                                            mode="multiple"
                                                            selectAllMode="allPages"
                                                            // showCheckBoxesMode="onClick"
                                                        />

                                                        {/* {states.resultViewStructure[0].view.exportable === 1 &&  */}
                                                        <Export 
                                                            enabled={true} 
                                                            // allowExportSelectedData={true} 
                                                        />
                                                        {/* } */}
                                                        {states.resultViewStructure[0].view.dataGridProperties.fieldsDefs.map(
                                                            (sender, index) => {
                                                                //console.log(resultViewStructure[0])
                                                                //checar se colua tem clunas filhas se tiver entrar
                                                                //o segndo nivel do map 
                                                                if (sender.gridVisible === 1 ||
                                                                    sender.gridVisibleForCustomization === 1) {
                                                                    //if(sender.gridVisibleForCustomization ===1){
                                                                    if (sender.editorType === "dxCheckBox") {
                                                                        // senderFieldNamesCheckBox.push(sender.fieldName)
                                                                        return (
                                                                            <Column
                                                                                key={index}
                                                                                fixed={sender.fixed}
                                                                                visible={sender.gridVisible === 1}
                                                                                groupIndex={sender.groupIndex}
                                                                                defaultSortOrder={sender.sortOrder}
                                                                                defaultSortIndex={sender.sortIndex}
                                                                                showInColumnChooser={sender.gridVisibleForCustomization === 1}
                                                                                dataField={sender.fieldName}
                                                                                allowEditing={sender.readOnly != 1}
                                                                                caption={sender.fieldCaption}
                                                                                // editorOptions={editorOptionsColumns}
                                                                                dataType={"boolean"}
                                                                            >

                                                                            </Column>
                                                                        )
                                                                    }
                                                                    else if (sender.editorType === "upfProgressBar") {
                                                                        return (

                                                                            <Column
                                                                                dataField={sender.fieldName}
                                                                                caption={sender.fieldCaption}
                                                                                dataType="number"
                                                                                format="percent"
                                                                                alignment="right"
                                                                                allowGrouping={false}
                                                                                cellRender={PercentageBar}
                                                                                cssClass="bullet"
                                                                                key={index}
                                                                                fixed={sender.fixed}
                                                                                visible={sender.gridVisible === 1}
                                                                                groupIndex={sender.groupIndex}
                                                                                defaultSortOrder={sender.sortOrder}
                                                                                defaultSortIndex={sender.sortIndex}
                                                                                showInColumnChooser={sender.gridVisibleForCustomization === 1}
                                                                                allowEditing={sender.readOnly != 1}

                                                                            />
                                                                        )
                                                                    }
                                                                    //upfpoke => (p)endente (ok) e (e)rro
                                                                    else if (sender.editorType === "upfPoke") {
                                                                        return (

                                                                            <Column
                                                                                dataField={sender.fieldName}
                                                                                caption={sender.fieldCaption}
                                                                                // dataType="number"
                                                                                // format="percent"
                                                                                alignment="center"
                                                                                allowGrouping={false}
                                                                                cellRender={PokeComponent}
                                                                                // cssClass="bullet"
                                                                                key={index}
                                                                                fixed={sender.fixed}
                                                                                visible={sender.gridVisible === 1}
                                                                                groupIndex={sender.groupIndex}
                                                                                defaultSortOrder={sender.sortOrder}
                                                                                defaultSortIndex={sender.sortIndex}
                                                                                showInColumnChooser={sender.gridVisibleForCustomization === 1}
                                                                                allowEditing={sender.readOnly != 1}

                                                                            />
                                                                        )
                                                                    }

                                                                    else if ((sender.editorType === "dxDateBox" || sender.editorType === "dxCalendar") && sender.fieldType === "datetime") {
                                                                        return (
                                                                            <Column
                                                                                key={index}
                                                                                fixed={sender.fixed}
                                                                                visible={sender.gridVisible === 1}
                                                                                groupIndex={sender.groupIndex}
                                                                                defaultSortOrder={sender.sortOrder}
                                                                                defaultSortIndex={sender.sortIndex}
                                                                                showInColumnChooser={sender.gridVisibleForCustomization === 1}
                                                                                dataField={sender.fieldName}
                                                                                allowEditing={sender.readOnly != 1}
                                                                                caption={sender.fieldCaption}
                                                                                dataType={"datetime"}
                                                                                format="shortDateShortTime"
                                                                            />
                                                                        )
                                                                    }

                                                                    else if (sender.editorType === "upfTime") {
                                                                        return (
                                                                            <Column
                                                                                key={index}
                                                                                fixed={sender.fixed}
                                                                                visible={sender.gridVisible === 1}
                                                                                groupIndex={sender.groupIndex}
                                                                                defaultSortOrder={sender.sortOrder}
                                                                                defaultSortIndex={sender.sortIndex}
                                                                                showInColumnChooser={sender.gridVisibleForCustomization === 1}
                                                                                dataField={sender.fieldName}
                                                                                allowEditing={sender.readOnly != 1}
                                                                                caption={sender.fieldCaption}
                                                                                dataType={"datetime"}
                                                                                format="shortTime"
                                                                            />
                                                                        )
                                                                    }
                                                                    else if ((sender.editorType === "dxDateBox" || sender.editorType === "dxCalendar") && sender.fieldType === "date") {
                                                                        return (
                                                                            <Column
                                                                                key={index}
                                                                                fixed={sender.fixed}
                                                                                visible={sender.gridVisible === 1}
                                                                                groupIndex={sender.groupIndex}
                                                                                defaultSortOrder={sender.sortOrder}
                                                                                defaultSortIndex={sender.sortIndex}
                                                                                showInColumnChooser={sender.gridVisibleForCustomization === 1}
                                                                                dataField={sender.fieldName}
                                                                                allowEditing={sender.readOnly != 1}
                                                                                caption={sender.fieldCaption}
                                                                                dataType={"datetime"}
                                                                                format="shortDate"
                                                                            />
                                                                        )
                                                                    }
                                                                    else if (sender.editorType === "upfHtmlViewer" || sender.editorType === 'upfHtmlBox') {
                                                                        return (
                                                                            <Column
                                                                                key={index}
                                                                                fixed={sender.fixed}
                                                                                visible={sender.gridVisible === 1}
                                                                                groupIndex={sender.groupIndex}
                                                                                defaultSortOrder={sender.sortOrder}
                                                                                defaultSortIndex={sender.sortIndex}
                                                                                showInColumnChooser={sender.gridVisibleForCustomization === 1}
                                                                                dataField={sender.fieldName}
                                                                                allowEditing={sender.readOnly !== 1}
                                                                                caption={sender.fieldCaption}
                                                                                dataType={sender.fieldType} 
                                                                                cellRender={(cellData) => {
                                                                                    return(
                                                                                        <UpfHtmlEditor 
                                                                                            referencia={_dataGridViewFormRef.current.instance}
                                                                                            setFormDataJson={props.setFormDataJson}
                                                                                            setDataEntity={props.setDataEntity}
                                                                                            dataEntity={cellData.data}
                                                                                            entityStructor={sender}
                                                                                            colSpan={sender.colSpan} 
                                                                                            caption={sender.fieldCaption} 
                                                                                            dataField={sender.fieldName}
                                                                                            disabled={true} 
                                                                                            hint={sender.hint}
                                                                                            setTituloQueryFormPopup={props.setTituloQueryFormPopup}
                                                                                            showEditBar={false}
                                                                                            cellData={cellData}
                                                                                        />
                                                                                    )}
                                                                                }
                                                                            />
                                                                        )
                                                                    }
                                                                    else if (sender.editorType === "upfAudio") {
                                                                        return (
                                                                            <Column
                                                                            key={index}
                                                                                fixed={sender.fixed}
                                                                                visible={sender.gridVisible === 1}
                                                                                groupIndex={sender.groupIndex}
                                                                                defaultSortOrder={sender.sortOrder}
                                                                                defaultSortIndex={sender.sortIndex}
                                                                                showInColumnChooser={sender.gridVisibleForCustomization === 1}
                                                                                dataField={sender.fieldName}
                                                                                allowEditing={sender.readOnly !== 1}
                                                                                caption={sender.fieldCaption}
                                                                                dataType={sender.fieldType} 

                                                                                cellRender={(cellData) => {
                                                                                    return(
                                                                                        <UpfAudio 
                                                                                            referencia={_dataGridViewFormRef?.current?.instance}
                                                                                            setFormDataJson={props.setFormDataJson}
                                                                                            setDataEntity={props.setDataEntity}
                                                                                            dataEntity={cellData.data?.[sender.fieldName]}
                                                                                            entityStructure={sender}
                                                                                            colSpan={sender.colSpan} 
                                                                                            caption={sender.fieldCaption} 
                                                                                            dataField={sender.fieldName}
                                                                                            // disabled={!modeEditDataGrid.edit} 
                                                                                            hint={sender.hint}
                                                                                            setTituloQueryFormPopup={props.setTituloQueryFormPopup}
                                                                                            // dadosDaEntidadeDetalheState={dadosDaEntidadeDetalheState}
                                                                                            // setDadosDaEntidadeDetalheState={setDadosDaEntidadeDetalheState}
                                                                                            // showEditBar={false}
                                                                                            cellData={cellData}
                                                                                        />
                                                                                    )}
                                                                                }
                                                                            />
                                                                        )
                                                                    }
                                                                    else if (sender.fieldType === "money") {
                                                                        return (
                                                                            <Column
                                                                                autoExpandGroup={false}
                                                                                visible={sender.gridVisible === 1}
                                                                                showInColumnChooser={sender.gridVisibleForCustomization === 1}
                                                                                // width={sender.fieldSize}
                                                                                dataField={sender.fieldName}
                                                                                caption={sender.fieldCaption}
                                                                                allowSearch={true}
                                                                                groupIndex={sender.groupIndex}
                                                                                defaultSortOrder={sender.sortOrder}
                                                                                defaultSortIndex={sender.sortIndex}
                                                                                // dataType={sender.dataType}
                                                                                // editorType={sender.editorType}
                                                                                format={{
                                                                                    style: 'currency',
                                                                                    currency: 'BRL',
                                                                                    precision: 2,
                                                                                }}
                                                                            />
                                                                        );
                                                                    }
                                                                    else {
                                                                        return (
                                                                            <Column
                                                                                autoExpandGroup={false}
                                                                                visible={sender.gridVisible === 1}
                                                                                showInColumnChooser={sender.gridVisibleForCustomization === 1}
                                                                                // width={sender.fieldSize}
                                                                                dataField={sender.fieldName}
                                                                                caption={sender.fieldCaption}
                                                                                allowSearch={true}
                                                                                groupIndex={sender.groupIndex}
                                                                                dataType={sender.dataType}
                                                                                editorType={sender.editorType}
                                                                                defaultSortOrder={sender.sortOrder}
                                                                                defaultSortIndex={sender.sortIndex}
                                                                                format={formatFunction(sender.fieldMask, "column", sender.fieldName, props.resultViewDataSet.length > 0 ? props.resultViewDataSet : [], false)}
                                                                            />
                                                                        );
                                                                    }
                                                                }
                                                            }
                                                        )
                                                        }
                                                        <Summary 
                                                            calculateCustomSummary={calculateCustomSummary}
                                                        >
                                                            <TotalItem
                                                                column={_dataGridViewFormRef?.current?.instance?.getVisibleColumns()[1]?.dataField}
                                                                summaryType="count"
                                                                customizeText={(data) => `Qtd: ${data.value}`}
                                                            />
                                                            <TotalItem
                                                                name={`{"tipo": "count", "columnName": "${_dataGridViewFormRef?.current?.instance?.getVisibleColumns()[1]?.dataField}", "key": "keyexpr"}`}
                                                                summaryType="custom"
                                                                displayFormat="Sel.: {0}"
                                                                showInColumn={_dataGridViewFormRef?.current?.instance?.getVisibleColumns()[1]?.dataField}
                                                            />
                                                            
                                                            {states.resultViewStructure[0].view.dataGridProperties.summary &&
                                                                states.resultViewStructure[0].view.dataGridProperties.summary.map((item, indice) => {
                                                                    if (item.fieldType === "money") {
                                                                        return (
                                                                            <TotalItem
                                                                                column={item.fieldName}
                                                                                summaryType={item.pivotGridSummaryType}
                                                                                fieldType={item.fieldType}
                                                                                showInGroupFooter={false}
                                                                                alignByColumn={true}
                                                                                format={{
                                                                                    style: 'currency',
                                                                                    currency: 'BRL',
                                                                                    precision: 2,
                                                                                }}
                                                                                customizeText={(data) => `Total: ${convertNumberToCurrency.format(data.value)}`}
                                                                            />
                                                                        )
                                                                    }
                                                                    // else {
                                                                    //     return (
                                                                    //         <TotalItem
                                                                    //             column={item.fieldName}
                                                                    //             summaryType={item.pivotGridSummaryType}
                                                                    //             fieldType={item.fieldType}
                                                                    //             showInGroupFooter={false}
                                                                    //             alignByColumn={true}
                                                                    //             format={{
                                                                    //                 precision: 2
                                                                    //             }}
                                                                    //         />
                                                                    //     )
                                                                    // }
                                                                })
                                                            }

                                                            {states.resultViewStructure[0].view.dataGridProperties.summary &&
                                                                states.resultViewStructure[0].view.dataGridProperties.summary.map((item, indice) => {
                                                                    if (item.fieldType === "money") {
                                                                        return (
                                                                            <TotalItem
                                                                                name={`{"tipo": "value", "columnName": "${item.fieldName}", "key": "keyexpr"}`}
                                                                                summaryType="custom"
                                                                                valueFormat={{
                                                                                    style: 'currency',
                                                                                    currency: 'BRL',
                                                                                    precision: 2,
                                                                                }}
                                                                                displayFormat="Sel.: {0}"
                                                                                showInColumn={item.fieldName}
                                                                            />
                                                                        )
                                                                    }
                                                                })
                                                            }
                                                            <GroupItem
                                                                column="id"
                                                                summaryType="count"
                                                                displayFormat="{0} itens" 
                                                            />

                                                            {states.resultViewStructure[0].view.dataGridProperties.summary &&
                                                                states.resultViewStructure[0].view.dataGridProperties.summary.map((item, indice) => {
                                                                    if (item.fieldType === "money") {
                                                                        return (
                                                                            <GroupItem
                                                                                column={item.fieldName}
                                                                                summaryType={item.pivotGridSummaryType}
                                                                                fieldType={item.fieldType}
                                                                            />
                                                                        )
                                                                    }
                                                                    else{
                                                                        <GroupItem
                                                                            column={item.fieldName}
                                                                            summaryType={item.pivotGridSummaryType}
                                                                            fieldType={item.fieldType}
                                                                            showInGroupFooter={false}
                                                                            alignByColumn={true}
                                                                            format={{precision: 2}}
                                                                        />
                                                                    }
                                                                })
                                                            }
                                                        </Summary>
                                                        {/* <SortByGroupSummaryInfo summaryItem="count" /> */}
                                                    </DataGrid>
                                                    {/* {(states.resultViewStructure[0].view.dataGridProperties.pagerVisible === 1 && selectedItensCount > 0) &&
                                                        <div>
                                                            <i>{selectedItensCount} registros selecionados</i>
                                                        </div>
                                                    } */}
                                                </div>
                                            </Item>
                                        </TabPanel>
                                    </div>
                                )}
                            </div>
                        </>
                    }
                </div>
            ) : (<Loading />)}
        </div>
    );
}
export default DataGridViewForm;