import './App.scss';
import {Route, Switch, Redirect, useHistory} from "react-router-dom"
import Login from "./Pages/Login";
import Main from "./Pages/Main";
import { useEffect} from 'react';
import { useRequestsApi } from "./Context/RequestsApi"
import {useBackendJson} from "./Context/BackendJson"
import { useState } from 'react';
import { httpRequest } from './functions';
import { applicationID } from './api/ApplicationID';
import UpfPopupMessage from './CardComponents/UpfPopupMessage';
import { alterarCorDestaqueTema } from './functions/alterarCorDestaqueTema';

const App = () => {
  const {requestsApi, setRequestsApi} = useRequestsApi()
  const [dataSourceLogin, setDataSourceLogin] = useState(JSON.parse(sessionStorage.getItem("getServerInfo")))
  const [dataSourceLoginDashboard, setDataSourceLoginDashboard] = useState(JSON.parse(sessionStorage.getItem("getDashboardInfo")));
  const [teste, setTeste] = useState()
  const {backendJson, setBackendJson} = useBackendJson()
  const [popupStates, setPopupStates] = useState({
    visible: false,
    message: null
  })


  const hidePopup = () => {
    setPopupStates({
      ...popupStates,
      visible: false
    })
  }

  function LoadBackendJson() {   
    alterarCorDestaqueTema(window.localStorage.getItem('dx-theme'))
      fetch(`${process.env.PUBLIC_URL}/backend.json`)
      .then((resp) => {
          resp.json()
          .then((backendJson) => {          

            sessionStorage.removeItem("requestApiRevolution")
            //definindo protocolo usado na url
            let protocol = window.location.protocol;

            //carregando configuracoes backend.json
            let api_host = backendJson.api_host
            let api_port = backendJson.api_port;
            let batch_port = backendJson.batch_port;      
            let dashboard_host = backendJson.dashboard_host;
            let dashboard_port = backendJson.dashboard_port;
            
            //verificando se o host nao existe no backend.json pra usar o host da url
            if (!backendJson.api_host){
              api_host = window.location.hostname;
            }

            if (!backendJson.dashboard_host){
              dashboard_host = window.location.hostname;
            }
            backendJson["protocol"] = protocol;
            //se nao tiver configurado batch_port, usar port
            if (!batch_port){
              batch_port = api_port;
            }
            let apiUrlBase = `${protocol}//${api_host}:${api_port}`
            //altera a variavel no json pro hostname que ficou, pq é utilizada em outros locais
            backendJson.api_host = api_host;
            //estado global com todos os dados passado no backend.json
            setBackendJson(backendJson)
            
            if(!JSON.parse(sessionStorage.getItem("requestApiRevolution"))){
              httpRequest('POST', `${apiUrlBase}/techfyService/getServerInfo`, null, {
                applicationId: applicationID,
                hostApi: api_host
              })
              .then((sender) => {
                const senderJson = JSON.parse(sender)
                const basicAuth = `${senderJson.userName}:${senderJson.password}`
                sessionStorage.setItem("getServerInfo", sender)
                setDataSourceLogin(senderJson)
                
                const requests =  JSON.stringify({
                  "getLookupTable": `${apiUrlBase}/techfyService/getLookupTable`,
                  "getEntityStructure": `${apiUrlBase}/techfyService/getEntityStructure`,
                  "getDataEntity": `${apiUrlBase}/techfyService/getDataEntity`,
                  "updateRecordFromJson": `${apiUrlBase}/techfyService/updateRecordFromJson`,
                  "insertRecordFromJson": `${apiUrlBase}/techfyService/insertRecordFromJson`,
                  "getEntityRecords": `${apiUrlBase}/techfyService/getEntityRecords`,
                  "entityAttachmentUpload": `${apiUrlBase}/techfyService/entityAttachmentUpload`,
                  "getEntityAttachment": `${apiUrlBase}/techfyService/getEntityAttachment`,
                  "getInputQueryStructure": `${apiUrlBase}/techfyService/getInputQueryStructure`,
                  "loginUserCRM": `${apiUrlBase}/techfyService/loginUserCRM`,
                  "deleteRecordFromJson": `${apiUrlBase}/techfyService/deleteRecordFromJson`,
                  "getEntityList": `${apiUrlBase}/techfyService/getEntityList`,
                  "getEntityQueryFields": `${apiUrlBase}/techfyService/getEntityQueryFields`,
                  "createTablesAndFieldsFromEntity": `${apiUrlBase}/techfyService/createTablesAndFieldsFromEntity`,
                  "addColumnsFromView": `${apiUrlBase}/techfyService/addColumnsFromView`,
                  "getSecurityGroupPermissionMenuItems": `${apiUrlBase}/techfyService/getSecurityGroupPermissionMenuItems`,
                  "getSecurityGroupPermissionViews": `${apiUrlBase}/techfyService/getSecurityGroupPermissionViews`,
                  "getSecurityGroupPermissionEntidades": `${apiUrlBase}/techfyService/getSecurityGroupPermissionEntidades`,
                  "getSecurityGroupEspecialPermission": `${apiUrlBase}/techfyService/getSecurityGroupEspecialPermission`,
                  "customUrl": `${apiUrlBase}/techfyService/`,
                  "execProcedure": `${apiUrlBase}/techfyService/execProcedure`,
                  "execProcedureJson": `${apiUrlBase}/techfyService/execProcedureJson`,
                  "execProcedureJsonParameter": `${apiUrlBase}/techfyService/execProcedureJsonParameter`,
                  "getServerInfo": `${apiUrlBase}/techfyService/getServerInfo`,
                  "getSystemLog": `${apiUrlBase}/techfyService/getSystemLog`,
                  "getEntityFields": `${apiUrlBase}/techfyService/getEntityFields`,
                  "getHestiaLogProcessamento": `${apiUrlBase}/techfyService/getHestiaLogProcessamento`,
                  "hestiaAtiva": `${apiUrlBase}/techfyService/hestiaAtiva`,
                  "getTarefasFuncionario": `${apiUrlBase}/techfyService/getTarefasFuncionario`,
                  "gravarTreeView": `${apiUrlBase}/techfyService/gravarTreeView`,
                  "getDataEntityArraySearch": `${apiUrlBase}/techfyService/getDataEntityArraySearch`,
                  "getParcelasVencidasVincendas": `${apiUrlBase}/cobrancaService/getParcelasVencidasVincendas`,
                  "getOpcoesParcelamentoCredor": `${apiUrlBase}/cobrancaService/getOpcoesParcelamentoCredor`,
                  "getFaseCalculadaTitulo": `${apiUrlBase}/cobrancaService/getFaseCalculadaTitulo`,
                  "incluirAcionamento": `${apiUrlBase}/cobrancaService/incluirAcionamento`,
                  "getContatosTitulo": `${apiUrlBase}/cobrancaService/getContatosClienteTituloId`,
                  "getMensagensTitulo": `${apiUrlBase}/cobrancaService/getMensagensTituloId`,
                  "getWidget": `${apiUrlBase}/telecobrancaService/getWidget`,
                  "getProximaFicha": `${apiUrlBase}/telecobrancaService/getProximaFicha`,
                  "getTelecobrancaStructure": `${apiUrlBase}/telecobrancaService/getTelecobrancaStructure`,
                  "getDataTelecobranca": `${apiUrlBase}/telecobrancaService/getDataTelecobranca`,
                  "getBoletoPDF": `${apiUrlBase}/boletoService/getBoletoPDF`,
                  "gerarAcordo": `${apiUrlBase}/telecobrancaService/gerarAcordo`,
                  "getPausasDisponiveis": `${apiUrlBase}/telecobrancaService/getPausasDisponiveis`,
                  "solicitarPausa": `${apiUrlBase}/telecobrancaService/solicitarPausa`,
                  "abrirPausa": `${apiUrlBase}/telecobrancaService/abrirPausa`,
                  "fecharPausa": `${apiUrlBase}/telecobrancaService/fecharPausa`,
                  "getProximoAgendamentoHorarioProgramado": `${apiUrlBase}/telecobrancaService/getProximoAgendamentoHorarioProgramado`,
                  "prendeFicha": `${apiUrlBase}/telecobrancaService/prendeFicha`,
                  "getProximoAgendamentoHorarioProgramado": `${apiUrlBase}/telecobrancaService/getProximoAgendamentoHorarioProgramado`,
                  "prendeFicha": `${apiUrlBase}/telecobrancaService/prendeFicha`,
                  "getDiscadorLogProcessamento": `${apiUrlBase}/techfyService/getDiscadorLogProcessamento`,
                  "updateVersionBackend": `${apiUrlBase}/techfyService/updateVersion/api`,
                  "updateVersionFrontend": `${apiUrlBase}/techfyService/updateVersion/front`,
                  "updateVersionDatabase": `${apiUrlBase}/techfyService/updateVersion/database`,
                  "getCampanhaDesconto": `${apiUrlBase}/ituranService/getCampanhaDesconto`,
                  "getPendencias": `${apiUrlBase}/ituranService/getPendencias`,
                  "getCodigoSolicitacao": `${apiUrlBase}/ituranService/getCodigoSolicitacao`,
                  "getFormasPagamento": `${apiUrlBase}/ituranService/getFormasPagamento`,
                  "getCobrancasPontuais": `${apiUrlBase}/ituranService/getCobrancasPontuais`,
                  "getTelecobrancaViews": `${apiUrlBase}/telecobrancaService/getTelecobrancaViews`,
                  "gerarCobranca": `${apiUrlBase}/ituranService/gerarCobranca`,
                  "getCobrancasPontuaisTaxas": `${apiUrlBase}/ituranService/getCobrancasPontuaisTaxas`,
                  "getListarOcorrencia": `${apiUrlBase}/ituranService/getListarOcorrencia`,
                  "adicionarConclusao": `${apiUrlBase}/ituranService/adicionarConclusao`,
                  "adicionarHistorico": `${apiUrlBase}/ituranService/adicionarHistorico`,
                  "getListarSolucao": `${apiUrlBase}/ituranService/getListarSolucao`,
                  "getBoleto": `${apiUrlBase}/ituranService/getBoleto`,
                  "acordoEfetivar": `${apiUrlBase}/cobranSasService/acordoEfetivar`,
                  "acordoSimular": `${apiUrlBase}/cobranSasService/acordoSimular`,
                  "negociacoes": `${apiUrlBase}/cobranSasService/negociacoes`,
                  "getTemplates": `${apiUrlBase}/whatsAppService/getTemplates`,
                  "basicAuth": basicAuth,
                  "getDashboardServerInfo": `${protocol}//${dashboard_host}:${dashboard_port}/api/dashboard/version`,
                  "dashBoardEndpoint": `${protocol}//${dashboard_host}:${dashboard_port}/api/dashboard`,
                  "fileManagerEndpoint": `${protocol}//${dashboard_host}:${dashboard_port}/api/file-manager-file-system`,

                  "getInputQueryData": `${protocol}//${api_host}:${batch_port}/techfyService/getInputQueryData`,
                  "getViewStructure": `${protocol}//${api_host}:${batch_port}/techfyService/getViewStructure`,
                  "getViewDataSet": `${protocol}//${api_host}:${batch_port}/techfyService/getViewDataSet`,
                  "gerarBoleto": `${protocol}//${api_host}:${batch_port}/boletoService/gerarBoleto`
                  })
                setRequestsApi(JSON.parse(requests))
                sessionStorage.setItem("requestApiRevolution", requests)
              })
              .catch((error) => {
                setPopupStates({
                  visible: true,
                  message: "Não foi possível conectar com o servidor! Tente novamente mais tarde."
                })
              })
            }

            if(!JSON.parse(sessionStorage.getItem("getDashboardInfo")) && dashboard_host && dashboard_port){
              // httpRequest("GET", `${protocol}//${hostNameDashBoard}:${portDashBoard}/api/dashboard/version`)
              httpRequest("GET", `${protocol}//${dashboard_host}:${dashboard_port}/api/dashboard/version`)
              .then((sender) => {
                  const severInfoResult = JSON.parse(sender)
                  setDataSourceLoginDashboard(severInfoResult) 
                  sessionStorage.setItem("getDashboardInfo", sender)
              })
              .catch((error) => {
                console.log(error)
              })
            }
          })
      })
      .catch((error) => {
        setPopupStates({
          visible: true,
          message: "Não foi possível conectar com o servidor! Tente novamente mais tarde."
        })
      })
  }

  const isConnected = () =>{
    //trocar essa logica pra colocar so um nome quando o metodo do login estiver pronto
    if(sessionStorage.getItem("MenuDataSource") !== null || 
      sessionStorage.getItem("@EquipeID") !== null) 
    {
      return true
    }
    else 
    {
      return false
    }
    
    //return sessionStorage.getItem("MenuDataSource") !== null
  }
  // const navigate = useNavigate(); 
  const history = useHistory(); 


  useEffect(()=>{    
    if(!window.localStorage.getItem('dx-theme')){
      window.localStorage.setItem('dx-theme', "greendark")
      document.querySelector(':root').style.setProperty('--cor-destaque-tema', "rgb(205, 220, 57)")
      document.querySelector(':root').style.setProperty('--bloquear-transparencia-fundo', '0');

    }
        
    if(isConnected()){
      // navigate("/")
      history.push("/")
    }
    console.log('LoadBackendJson')
    LoadBackendJson()
    //return () => LoadBackendJson() //executa o LoadBackendJson apenas 1x (DEU CERTO APENAS EM MODO DEBUG, AO GERAR BUILD, NAO ABRE O MAIN)
  // },[navigate])
  },[history])

  return (
    <Switch>
      <Route exact path="/login"> 
        <Login 
          setTeste={setTeste}
          dataSourceLogin={dataSourceLogin}
          dataSourceLoginDashboard={dataSourceLoginDashboard}
        />
        <UpfPopupMessage
          visible={popupStates.visible}
          hidePopup={hidePopup}
          message={popupStates.message}
        />
      </Route>
      {isConnected() ? 
      (
        <Route exact path="/">
          <Main
            dataSourceLogin={dataSourceLogin}
            dataSourceLoginDashboard={dataSourceLoginDashboard}
          />
        </Route> 
      )
      :
      (
        <Redirect to="/login"/>
      )}
    </Switch>
  );
}

export default App;