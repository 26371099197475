import './style.css'
import TabPanel, { Item as TabItem } from 'devextreme-react/tab-panel';
import DataGrid, {
    FilterRow,
    Sorting,
    ColumnChooser,
    HeaderFilter,
    Column,
    Scrolling,
    Lookup,
    Selection,
    Grouping,
    GroupPanel,
    Pager,
    Editing,
    Paging,
    SearchPanel
    } from 'devextreme-react/data-grid';
import { 
    Popup, 
    ToolbarItem,
} from 'devextreme-react/popup';
import UpfPopupMessage from "../../CardComponents/UpfPopupMessage"
import RecordFormPopup from "../RecordForm/RecordFormPopup";
import { Toast } from 'devextreme-react/toast';
import SelectBox from 'devextreme-react/select-box';
import { useEffect, useState, useCallback, useRef, memo } from 'react'
import { httpRequest } from '../../functions';
import { useRequestsApi } from '../../Context/RequestsApi';
import { Button } from 'devextreme-react';
import { applicationID, relationId } from '../../api/ApplicationID';
import { LoadPanel } from 'devextreme-react/load-panel';
import { validatePermission } from '../../functions/validatePermission';
import notify from 'devextreme/ui/notify';

const MensagemCustomForm = (props) => {
    console.log(props)

    const refDataGridConsultaBoletoFormTelefones = useRef(null)
    const refDataGridConsultaBoletoFormEmails = useRef(null)

    const [contatosDataSourceState, setContatosDataSourceState] = useState([])
    const [emailsDataSourceState, setEmailsDataSourceState] = useState([])

    const {requestsApi, setRequestsApi} = useRequestsApi()

    const [modeEditDataGridTelefonesState, setModeEditDataGridTelefonesState] = useState(false)
    const [modeEditDataGridEmailsState, setModeEditDataGridEmailsState] = useState(false)


    const [dataLookupState, setDataLookupState] = useState()

    const [contatosSelecionadosState, setContatosSelecionadosState] = useState()
    const [emailState, setEmailState] = useState([])

    const [templateSelecionadoEmailState, setTemplateSelecionadoEmailState] = useState()
    const [templateSelecionadoSmsState, setTemplateSelecionadoSmsState] = useState()
    const [templateSelecionadoWhatsappState, setTemplateSelecionadoWhatsappState] = useState()

    const [logMensagensState, setLogMensagensState] = useState([])

    const [popupRecordFormOpen, setPopupRecordFormOpen] = useState(false)
    const [desabilitarBotoesState, setDesabilitarBotoesState] = useState(true)
    const [IDValueDetailEntity, setIDValueDetailEntity] = useState()
    const [detailEntityName, setDetailEntityName] = useState()
    const [detailCaptionName, setDetailCaptionName] = useState()
    const [keyFieldNameState, setKeyFieldNameState] = useState()
    const [entityStructureRecordFormPopupState, setEntityStructureRecordFormPopupState] = useState()
    const [dataLookupRecordFormPopupState, setDataLookupRecordFormPopupState] = useState()
    const [dataEntity, setDataEntity] = useState()
    const [masterDetailEntity, setMasterDetailEntity] = useState()
    const [popupState, setPopupState] = useState(false)
    const [popupRecordFormAdd, setPopupRecordFormAdd] = useState()
    const [embebedIdValueState, setEmbebedIdValueState] = useState()
    const [embebedEntityNameState, setEmbebedEntityNameState] = useState()
    const [stateModeEdit, setStateModeEdit] = useState(false);
    

    const loadingPanelOptions = {
        loadPanelVisible: false,
        showIndicator: true,
        shading: false,
        showPane: true,
        closeOnOutsideClick: false
    }
    const [loadingPanelState, setLoadingPanelState] = useState(loadingPanelOptions)

    const hideLoadPanel = useCallback(() => {
        setLoadingPanelState({
            ...loadingPanelState,
            loadPanelVisible: false,
        })
    }, [loadingPanelState])

    const showLoadPanel = useCallback(() => {
        setLoadingPanelState({
            ...loadingPanelState,
            loadPanelVisible: true,
        })
    }, [loadingPanelState])

    const [visible, setVisible] = useState(false)
    const [message, setMessage] = useState("")

    const [states, setStates] = useState({
        toastVisible: false,
        toastType: "info",
        toastMessage: ""
    })

    const toastConfiguration = (visible, type, message) => {
        setStates({...states, 
            toastVisible: visible,
            toastType: type,
            toastMessage: message
        })
    }

    const onHidingToast = () => {
        setStates({...states, toastVisible: false})
    }

    const refreshData = () => {

        httpRequest('GET', `${requestsApi.getMensagensTitulo}?id=${props.tituloId}`, requestsApi.basicAuth)
            .then((sender) => {
                const resultGetMensagensTituloJson = JSON.parse(sender)
                setLogMensagensState(resultGetMensagensTituloJson)
                // console.log(sender)
            })
            .catch((error) => {
                console.log(error)
                setMessage(error.data.error)
                setVisible(true)
            })
    }

    const [permissoesEmailsState, setPermissoesEmailsState] = useState()
    const [permissoesContatosState, setPermissoesContatosState] = useState()

    useEffect(() => {
        httpRequest('POST', requestsApi.getEntityStructure, requestsApi.basicAuth, {
            entityName: "_cadastro_contatos",
            applicationId: applicationID,
        })
        .then((sender) => {
            const estruturaContatos = JSON.parse(sender)

            setPermissoesContatosState(validatePermission(estruturaContatos?.[0]?.masterEntity?.objectid))
            httpRequest('POST', requestsApi.getEntityStructure, requestsApi.basicAuth, {
                entityName: "_cadastro_emails",
                applicationId: applicationID,
            })
            .then((sender) => {
            const estruturaEmails = JSON.parse(sender)

                setPermissoesEmailsState(validatePermission(estruturaEmails?.[0]?.masterEntity?.objectid))
            })
            .catch((error) => {
                console.log(error)
                setMessage(error.data.error)
                setVisible(true)
            })
        })
        .catch((error) => {
            console.log(error)
            setMessage(error.data.error)
            setVisible(true)
        })
    }, [])

    const enviarMensagem = (procedure, templateSelecionado) => {
        if(contatosSelecionadosState?.length > 0){
            showLoadPanel()
            const arrayIdsSelecionados = []

            for(let c = 0; c <= contatosSelecionadosState.length - 1; c++){
                arrayIdsSelecionados.push(contatosSelecionadosState[c].id)
            }

            const objectEnviarMensagem = {
                "applicationId": applicationID,
                "relationId": relationId,
                "procedure": procedure,
                "json": {
                    tituloId: props.tituloId,
                    "templateId": templateSelecionado,
                },
                "idValue": arrayIdsSelecionados
            }
    
            httpRequest('POST', requestsApi.execProcedureJsonParameter, requestsApi.basicAuth, objectEnviarMensagem)
            .then((sender) => {
                const resultGetViewJson = JSON.parse(sender)
                refreshData()
                hideLoadPanel()
                toastConfiguration(true, "success", resultGetViewJson.mensagem)
                // console.log(sender)
            })
            .catch((error) => {
                hideLoadPanel()
                console.log(error)
                setMessage(error.data.error)
                setVisible(true)
            })
        }
        else{
            setMessage("Nenhum telefone foi selecionado.")
            setVisible(true)
        }
    }

    const enviarEmail = (procedure, templateSelecionado) => {
        if(emailState?.length > 0){
            showLoadPanel()
            const arrayIdsSelecionados = []

            for(let c = 0; c <= emailState.length - 1; c++){
                arrayIdsSelecionados.push(emailState[c].id)
            }

            const objectEnviarMensagem = {
                "applicationId": applicationID,
                "relationId": relationId,
                "procedure": procedure,
                "json": {
                    tituloId: props.tituloId,
                    "templateId": templateSelecionado,
                },
                "idValue": arrayIdsSelecionados
            }
    
            httpRequest('POST', requestsApi.execProcedureJsonParameter, requestsApi.basicAuth, objectEnviarMensagem)
            .then((sender) => {
                const resultGetViewJson = JSON.parse(sender)
                refreshData()
                hideLoadPanel()
                toastConfiguration(true, "success", resultGetViewJson.mensagem)
                // console.log(sender)
            })
            .catch((error) => {
                hideLoadPanel()
                console.log(error)
                setMessage(error.data.error)
                setVisible(true)
            })
        }
        else{
            setMessage("Nenhum e-mail foi selecionado.")
            setVisible(true)
        }
    }

    const RenderRecordFormPopup = memo(() => {
        return (
            <RecordFormPopup
                type={"recordForm"}
                id={Math.random()}
                // idValue={props.tituloId}
                entityName={detailEntityName}
                caption={detailCaptionName}
                keyFieldNameState={keyFieldNameState ? keyFieldNameState : props.keyFieldNameState}
                masterEntityId={props.clienteId}
                popupRecordForm={true}
                entityStructure={entityStructureRecordFormPopupState}
                dataLookup={dataLookupRecordFormPopupState}
                popupState={popupState}
                setPopupState={setPopupState}
                popupRecordFormAdd={popupRecordFormAdd}
                setPopupRecordFormOpen={setPopupRecordFormOpen}
                popupRecordFormOpen={popupRecordFormOpen}
                masterDetailEntity={dataEntity && dataEntity?.detailEntity}
                embebedIdValueState={embebedIdValueState}
                embebedEntityNameState={embebedEntityNameState}
            />
        );
    })

    useEffect(() => {
        if(requestsApi){
            httpRequest('POST', requestsApi.getDataEntity, requestsApi.basicAuth, {
                "applicationId": applicationID
                ,"entityName": '_cadastro_contatos'
                ,"fieldName": "_cadastro_id"
                ,"expressao": props.clienteId
            })
            .then((sender) => {
                const resultGetContatosTituloJson = JSON.parse(sender)
                setContatosDataSourceState(resultGetContatosTituloJson?.masterEntity?.[0]?.records)
                // console.log(sender)
            })
            // .catch((error) => {
            //     console.log(error)
            //     setMessage(error.data.error)
            //     setVisible(true)
            // })

            httpRequest('POST', requestsApi.getDataEntity, requestsApi.basicAuth, {
                "applicationId": applicationID
                ,"entityName": '_cadastro_emails'
                ,"fieldName": "_cadastro_id"
                ,"expressao": props.clienteId
            })
            .then((sender) => {
                const resultGetContatosTituloJson = JSON.parse(sender)
                setEmailsDataSourceState(resultGetContatosTituloJson?.masterEntity?.[0]?.records)
                // console.log(sender)
            })
            // .catch((error) => {
            //     console.log(error)
            //     setMessage(error.data.error)
            //     setVisible(true)
            // })

            httpRequest('GET', `${requestsApi.getMensagensTitulo}?id=${props.tituloId}`, requestsApi.basicAuth)
            .then((sender) => {
                const resultGetMensagensTituloJson = JSON.parse(sender)
                setLogMensagensState(resultGetMensagensTituloJson)
                // console.log(sender)
            })
            .catch((error) => {
                console.log(error)
                setMessage(error.data.error)
                setVisible(true)
            })


            const sendLookupRequest = {
                "lookUpEntities": [
                    {
                        "lookUpEntity": "_templateemail"
                    },
                    {
                        "lookUpEntity": "_templatesms"
                    },
                    {
                        "lookUpEntity": "_cargo"
                    },
                    {
                        "lookUpEntity": "_pontuacao_contato",
                    },
                    {
                        "lookUpEntity": "_tipo_contato",
                    }
                ]
            }

            httpRequest('POST', requestsApi.getLookupTable, requestsApi.basicAuth, sendLookupRequest)
            .then(senderLookup => {
                const resultLookup = JSON.parse(senderLookup)
                // setDataLookupState(resultLookup)


                httpRequest('POST', requestsApi.getTemplates, requestsApi.basicAuth, {
                    "relationId": relationId
                })
                .then(senderLookup => {
                    const getTemplatesWhatsApp = JSON.parse(senderLookup)
                    resultLookup["_templatewhatsapp"] = getTemplatesWhatsApp
                    setDataLookupState(resultLookup)
                    
                })
                .catch((error) => {
                    setDataLookupState(resultLookup)
                    console.log(error)
                    setMessage(error.data.error)
                    setVisible(true)
                })


            })
            .catch((error) => {
                console.log(error)
                setMessage(error.data.error)
                setVisible(true)
            })
        }
    }, [requestsApi])

    const hidePopup = () => {
        setVisible(false)
    }

    const fieldsPermissionUpdateArray = []

    const recordInsertJsonDetail = (entityNameGrid, formData) => {
        delete formData.__KEY__;
        showLoadPanel();
        httpRequest("POST", requestsApi.insertRecordFromJson, requestsApi.basicAuth,
        {
            "entityName": entityNameGrid,
            "json": formData,
            "applicationId": applicationID,
            "relationId": relationId
        })
        .then((sender) => {
            //const senderUpdateRecordFromJson = JSON.parse(sender)
            // props.modeEditOff()
            toastConfiguration(true, 'success', "Registro inserido com sucesso!")
            hideLoadPanel()
        })
        .catch((error) => {
            console.log(error)
        })
        // .catch((error) => {
        //     hideLoadPanel()
        //     if (error?.data?.error) {
        //         setMessageError(error.data.error)
        //         setPopup({
        //             ...popup
        //             , isVisible: true
        //             , message: "Atenção!"
        //         })
        //     }
        //     else {
        //         setMessageError(error?.statusText)
        //         setPopup({
        //             ...popup
        //             , isVisible: true
        //             , message: "Atenção!"
        //         })
        //     }
        // })
    }

    const recordUpdateJsonDetail = (entityNameGrid, jsonRow) => {
        if(jsonRow) {
            httpRequest("POST", 
            requestsApi.updateRecordFromJson, requestsApi.basicAuth, 
                {
                "entityName": entityNameGrid,
                "json": jsonRow,
                "applicationId": applicationID,
                "relationId": relationId
            })
            .then((sender) => {
                const senderUpdateRecordFromJson = JSON.parse(sender)
                // props.modeEditOff()  
                toastConfiguration(true, 'success', senderUpdateRecordFromJson.mensagem)   
            })
            .catch((error) => {
                console.log(error)
            })
            // .catch((error)=>{
            //     if(error.data.error) {
            //         hideLoadPanel()
            //         setMessageError(error.data.error)
            //         setPopup({
            //             ...popup
            //                 ,isVisible: true
            //                 ,message: "Atenção!" 
            //         })
            //     }
            //     else {
            //         hideLoadPanel()
            //         setMessageError(error.statusText)
            //         setPopup({
            //             ...popup
            //                 ,isVisible: true
            //                 ,message: "Atenção!" 
            //         })
            //     } 
            // })
        }
    }

    const recordDeleteJsonDetail = (entityNameGrid, jsonRowId) => {
            httpRequest("POST", 
            requestsApi.deleteRecordFromJson, requestsApi.basicAuth,
                {
                "entityName": entityNameGrid,
                "applicationId": applicationID,
                "relationId": relationId,
                "fieldName": 'id',
                "expressao": jsonRowId
            })
            .then((sender) => {
                const senderUpdateRecordFromJson = JSON.parse(sender)
                // modeEditOff()  
                toastConfiguration(true, 'success', senderUpdateRecordFromJson.mensagem)   
            })
            // .catch((error)=>{
            //     if(error.data.error) {
            //         hideLoadPanel()
            //         setMessageError(error.data.error)
            //         setPopup({
            //             ...popup
            //                 ,isVisible: true
            //                 ,message: "Atenção!" 
            //         })
            //     }
            //     else {
            //         hideLoadPanel()
            //         setMessageError(error.statusText)
            //         setPopup({
            //             ...popup
            //                 ,isVisible: true
            //                 ,message: "Atenção!" 
            //         })
            //     } 
            // })
    }
      
    const addRowTelefone = useCallback(() => {
        refDataGridConsultaBoletoFormTelefones.current.instance.addRow();
        refDataGridConsultaBoletoFormTelefones.current.instance.deselectAll();
    }, [refDataGridConsultaBoletoFormTelefones]);
      
    const addRowEmail = useCallback(() => {
        refDataGridConsultaBoletoFormEmails.current.instance.addRow();
        refDataGridConsultaBoletoFormEmails.current.instance.deselectAll();
    }, [refDataGridConsultaBoletoFormEmails]);

    const refreshDetailEntity = (entityNameGrid) => {
        httpRequest("POST", 
                requestsApi.getLookupTable, requestsApi.basicAuth,
                {
                    "lookUpEntities": [
                        {
                            "lookUpEntity": "_pontuacao_contato",
                        },
                        {
                            "lookUpEntity": "_tipo_contato",
                        }
                    ]
                }
            ).then((sender)=>{
                const senderDataLookup = JSON.parse(sender)

                setDataLookupState(senderDataLookup)
               
                httpRequest('POST', requestsApi.getDataEntity, requestsApi.basicAuth, 
                    {
                        "applicationId": applicationID
                        ,"entityName": entityNameGrid
                        ,"fieldName": "_cadastro_id"
                        ,"expressao": props.clienteId
                    }
                )
                .then(senderGetDataEntity=>{
                const resultGetDataEntity = JSON.parse(senderGetDataEntity);
                    if(entityNameGrid === "_cadastro_contatos"){
                        setContatosDataSourceState(resultGetDataEntity?.masterEntity?.[0]?.records)

                    }
                    else if(entityNameGrid === "_cadastro_emails"){
                        setEmailsDataSourceState(resultGetDataEntity?.masterEntity?.[0]?.records)

                    }
                })
                .catch((error) => {
                    console.log(error)
                    setMessage(error.data.error)
                    setVisible(true)
                })
            })
            .catch((error) => {
                console.log(error)
                setMessage(error.data.error)
                setVisible(true)
            })
    }

    const handleBatchSaveTelefone = (e) => {
        const insertArr = [];
        const updateArr = [];
        const removeArr = [];
        
        const requestTypes = {
            insert: i => {
                const jsonDataInsert = i.data
                delete jsonDataInsert.__KEY__;
                const insertFormData = {
                    ...jsonDataInsert,
                    "_cadastro_id": props?.clienteId
                }; 
                insertArr.push(insertFormData);
            },
            update: i => {

                const dataObjPermissionUpdate = {
                    "id": i.key.id
                };

                const fieldsNotPermissionUpdateArray = []

                const jsonDataUpdate = i.data
                // delete jsonDataUpdate.id;

                const dataKeysArray = Object.keys(jsonDataUpdate)

                for (let c = 0; c <= dataKeysArray.length - 1; c++){
                    if (fieldsPermissionUpdateArray.find((e) => e === dataKeysArray[c])) {
                        dataObjPermissionUpdate[dataKeysArray[c]] = jsonDataUpdate[dataKeysArray[c]]
                    }
                    else{
                        fieldsNotPermissionUpdateArray.push(dataKeysArray[c])
                    }
                };
                jsonDataUpdate["id"] = i.key.id
                updateArr.push(jsonDataUpdate);
                // if (Object.keys(dataObjPermissionUpdate)?.length > 1){
                //     updateArr.push(dataObjPermissionUpdate);
                // } 
                // else {
                //     refreshDetailEntity(props.entityNameGrid, "_cadastro_id");
                // }

                // if(fieldsNotPermissionUpdateArray.length > 0){
                //     let mensagemErroCamposSemPermissao = "Não foi possível atualizar "

                //     for(let c = 0; c <= fieldsNotPermissionUpdateArray.length - 1; c++){
                //         mensagemErroCamposSemPermissao = mensagemErroCamposSemPermissao + fieldsNotPermissionUpdateArray[c] + ", "
                //     }

                //     mensagemErroCamposSemPermissao = mensagemErroCamposSemPermissao + "pois usuário não tem permissão."
                //     setMessage(mensagemErroCamposSemPermissao)
                //     setVisible(true)
                // }

            },
            remove: i => {
                const removeFormData = {
                    ...i.data,
                    "id": i.key
                };
                removeArr.push(removeFormData);
            }
        }


        for (let i of e.changes) {
            if (requestTypes[i.type]) {
                requestTypes[i.type](i);
            }
        }


        // * Faz a requisição para inserts e updates em lote
        if (insertArr.length > 0) {
            recordInsertJsonDetail("_cadastro_contatos", insertArr, refDataGridConsultaBoletoFormTelefones);
        }
        if (updateArr.length > 0) {
            recordUpdateJsonDetail("_cadastro_contatos", updateArr, refDataGridConsultaBoletoFormTelefones);
        }

        // * Só faz o refresh se tiver algum insert ou delete
        if (insertArr.length > 0 || removeArr.length > 0) { 
            refreshDetailEntity("_cadastro_contatos");
        }

        setModeEditDataGridTelefonesState(false)

    };

    const onRowInsertingTelefone = (e) => {
        console.log(e)                               
        if (Object.keys(e.data).length === 0) {
            e.cancel = true;
            notify({ message: "Não é permitido adicionar registros vazios" }, "error", 1500);
        }
    }

    const onRowRemovedTelefone = (e) => {
        recordDeleteJsonDetail("_cadastro_contatos", e.key.id)
        refreshDetailEntity("_cadastro_contatos")
        setModeEditDataGridTelefonesState(false)
    }

    const handleBatchSaveEmail = (e) => {
        const insertArr = [];
        const updateArr = [];
        const removeArr = [];
        
        const requestTypes = {
            insert: i => {
                const jsonDataInsert = i.data
                delete jsonDataInsert.__KEY__;
                const insertFormData = {
                    ...jsonDataInsert,
                    "_cadastro_id": props?.clienteId
                }; 
                insertArr.push(insertFormData);
            },
            update: i => {
                const dataObjPermissionUpdate = {
                    "id": i.key.id
                };

                const fieldsNotPermissionUpdateArray = []

                const jsonDataUpdate = i.data
                // delete jsonDataUpdate.__KEY__;

                const dataKeysArray = Object.keys(jsonDataUpdate)

                for (let c = 0; c <= dataKeysArray.length - 1; c++){
                    if (fieldsPermissionUpdateArray.find((e) => e === dataKeysArray[c])) {
                        dataObjPermissionUpdate[dataKeysArray[c]] = jsonDataUpdate[dataKeysArray[c]]
                    }
                    else{
                        fieldsNotPermissionUpdateArray.push(dataKeysArray[c])
                    }
                };
                jsonDataUpdate["id"] = i.key.id
                updateArr.push(jsonDataUpdate);

                // if (Object.keys(dataObjPermissionUpdate)?.length > 1){
                //     updateArr.push(dataObjPermissionUpdate);
                // } 
                // else {
                //     refreshDetailEntity(props.entityNameGrid);
                // }

                // if(fieldsNotPermissionUpdateArray.length > 0){
                //     let mensagemErroCamposSemPermissao = "Não foi possível atualizar "

                //     for(let c = 0; c <= fieldsNotPermissionUpdateArray.length - 1; c++){
                //         mensagemErroCamposSemPermissao = mensagemErroCamposSemPermissao + fieldsNotPermissionUpdateArray[c] + ", "
                //     }

                //     mensagemErroCamposSemPermissao = mensagemErroCamposSemPermissao + "pois usuário não tem permissão."
                //     setMessage(mensagemErroCamposSemPermissao)
                //     setVisible(true)
                // }

            },
            remove: i => {
                const removeFormData = {
                    ...i.data,
                    "id": i.key
                };
                removeArr.push(removeFormData);
            }
        }


        for (let i of e.changes) {
            if (requestTypes[i.type]) {
                requestTypes[i.type](i);
            }
        }


        // * Faz a requisição para inserts e updates em lote
        if (insertArr.length > 0) {
            recordInsertJsonDetail("_cadastro_emails", insertArr, refDataGridConsultaBoletoFormEmails);
        }
        if (updateArr.length > 0) {
            recordUpdateJsonDetail("_cadastro_emails", updateArr, refDataGridConsultaBoletoFormEmails);
        }

        // * Só faz o refresh se tiver algum insert ou delete
        if (insertArr.length > 0 || removeArr.length > 0) { 
            refreshDetailEntity("_cadastro_emails");
        }

        setModeEditDataGridEmailsState(false)

    };

    const onRowInsertingEmail = (e) => {
        console.log(e)                               
        if (Object.keys(e.data).length === 0) {
            e.cancel = true;
            notify({ message: "Não é permitido adicionar registros vazios" }, "error", 1500);
        }
    }

    const onRowRemovedEmail = (e) => {
        recordDeleteJsonDetail("_cadastro_emails", e.key.id)
        refreshDetailEntity("_cadastro_emails")
        setModeEditDataGridEmailsState(false)

    }

    return (
        <>
            <UpfPopupMessage
                hidePopup={hidePopup}
                visible={visible}
                message={message}
            />

            <Toast
                visible={states.toastVisible}
                message={states.toastMessage}
                type={states.toastType}
                onHiding={onHidingToast}
                displayTime={1800}
                height={60}
            />

            <LoadPanel
                shadingColor="rgba(0,0,0,0.4)"
                visible={loadingPanelState.loadPanelVisible}
                // visible={true}
                showIndicator={loadingPanelState.showIndicator}
                shading={loadingPanelState.shading}
                showPane={loadingPanelState.showPane}
            />

            {popupRecordFormOpen && props.clienteId && <RenderRecordFormPopup />}

            <div style={{display: "flex"}}>

                <div className="div-grupos-gerar-acordo">               
                                
                    <h4 style={{marginTop: "0", marginBottom: "0.5em"}}>Telefones</h4>

                    <div className="btn-tabPanel">
                        <div className="buttons-tabpanel">
                            <Button            
                                icon="add"
                                hint={`Incluir um novo registro`}
                                visible={permissoesContatosState ? permissoesContatosState?._insert: false}
                                type="normal"
                                onClick={() => {
                                    if(modeEditDataGridTelefonesState){
                                        addRowTelefone()
                                    }
                                    else{
                                        httpRequest('POST', requestsApi.getEntityStructure, requestsApi.basicAuth, {
                                            entityName: '_cadastro_contatos',
                                            applicationId: applicationID,
                                        })
                                        .then((sender) => {
                                            setEntityStructureRecordFormPopupState(JSON.parse(sender))

                                            if(JSON.parse(sender)?.[0]?.lookUpEntities?.length > 0){
                                                
                                                httpRequest('POST', requestsApi.getLookupTable, requestsApi.basicAuth, {
                                                    "lookUpEntities": JSON.parse(sender)?.[0]?.lookUpEntities
                                                })
                                                .then((sender) => {
                                                    setDataLookupRecordFormPopupState(JSON.parse(sender))

                                                    setKeyFieldNameState('_cadastro_id')
                                                    setIDValueDetailEntity(null)
                                                    setDetailEntityName('_cadastro_contatos')
                                                    // setDetailCaptionName(senderDetailEntity.caption)
                                                    setPopupRecordFormOpen(true)
                                                    setPopupState(true)
                                                    setStateModeEdit(true)
                                                })
                                                .catch((error) => {
                                                    setMessage(error.data.error)
                                                    setVisible(true)
                                                })
                                            }
                                            else{
                                                setKeyFieldNameState('_cadastro_id')
                                                setIDValueDetailEntity(null)
                                                setDetailEntityName('_cadastro_contatos')
                                                // setDetailCaptionName(senderDetailEntity.caption)
                                                setPopupRecordFormOpen(true)
                                                setPopupState(true)
                                                setStateModeEdit(true)
                                            }
                                        })
                                        .catch((error) => {
                                            setMessage(error.data.error)
                                            setVisible(true)
                                        })
                                    }
                                }
                                }
                            />
                        </div>
                        
                        <div className="buttons-tabpanel">
                            <Button
                                icon="rowproperties"
                                hint="Editar registros em lote no grid"
                                type="normal"
                                visible={permissoesContatosState ? permissoesContatosState?._edit: false}
                                onClick={() => {
                                    setModeEditDataGridTelefonesState(!modeEditDataGridTelefonesState)
                                }}
                            />
                        </div>

                        <div className="buttons-tabpanel">
                            <Button
                                icon="refresh"
                                hint="Atualizar dados do servidor"
                                type="normal"
                                onClick={() => refreshDetailEntity("_cadastro_contatos")}
                            />
                        </div>
                        
                    </div>

                    <DataGrid
                        ref={refDataGridConsultaBoletoFormTelefones}
                        dataSource={contatosDataSourceState}
                        columnAutoWidth={true}
                        showBorders={true}
                        onRowInserting={onRowInsertingTelefone}
                        onRowRemoved={onRowRemovedTelefone}
                        onSaving={handleBatchSaveTelefone} 
                        onSelectionChanged={(sender) => {
                            // console.log(sender.component.getSelectedRowsData())
                            setContatosSelecionadosState(sender.component.getSelectedRowsData())
                        }}
                    >
                        <Selection 
                            mode="multiple"
                            keyExpr='ID'
                        />
                        <Scrolling 
                            rowRenderingMode='infinity'
                            showScrollbar='always'
                            >
                        </Scrolling>

                        <Paging defaultPageSize={6} />

                        <Sorting
                            mode="single"
                        />
                        
                        <Pager
                            visible={true}
                            // allowedPageSizes={10}
                            // showPageSizeSelector={true}
                            showInfo={true}
                            showNavigationButtons={true} 
                        />

                        <HeaderFilter
                            allowSearch={true}
                            visible={true}
                        />

                        <Editing
                            allowDeleting={modeEditDataGridTelefonesState && permissoesContatosState?._delete}
                            allowUpdating={modeEditDataGridTelefonesState && permissoesContatosState?._edit}
                            // allowAdding={modeEditDataGridTelefonesState && permissoesContatosState?._insert}
                            confirmDelete={true}
                            mode="batch"
                            useIcons={true}
                        //selectTextOnEditStart={selectTextOnEditStart}
                        //startEditAction={startEditAction}
                        />
                        <Column
                            caption="Deletar"
                            visible={modeEditDataGridTelefonesState && permissoesContatosState?._delete}
                            type="buttons"
                            width={110}
                        >
                            <Button
                                name="delete"
                            />
                        </Column>

                        <Column
                            dataField="_cryptnome"
                            caption="Nome"
                        ></Column>

                        <Column
                            dataField="_cargoid"
                            caption="Cargo"
                        >
                            <Lookup
                                dataSource={dataLookupState?._cargo}
                                valueExpr="id"
                                displayExpr="_descricao"
                            />  
                        </Column>

                        <Column
                            dataField="_cryptfone"
                            caption="Telefone"
                        ></Column>

                        <Column
                            dataField="_tipocontatoid"
                            caption="Tipo"
                        >
                            <Lookup
                                dataSource={dataLookupState?._tipo_contato}
                                valueExpr="id"
                                displayExpr="_descricao"
                            />               
                        </Column>

                        <Column
                            dataField="_pontuacaocontatoid"
                            caption="Pontuação"
                        >
                            <Lookup
                                dataSource={dataLookupState?._pontuacao_contato}
                                valueExpr="id"
                                displayExpr="_descricao"
                            />    
                        </Column>
                    </DataGrid>
                </div>  

                <div className="div-grupos-gerar-acordo">
                    <h4 style={{marginTop: "0", marginBottom: "0.5em"}}>E-mails</h4>

                    <div className="btn-tabPanel">
                        <div className="buttons-tabpanel">
                            <Button            
                                icon="add"
                                hint={`Incluir um novo registro`}
                                visible={permissoesEmailsState ? permissoesEmailsState?._insert: false}
                                type="normal"
                                onClick={() => {
                                    if(modeEditDataGridEmailsState){
                                        addRowEmail()
                                    }
                                    else{
                                        httpRequest('POST', requestsApi.getEntityStructure, requestsApi.basicAuth, {
                                            entityName: "_cadastro_emails",
                                            applicationId: applicationID,
                                        })
                                        .then((sender) => {
                                            setEntityStructureRecordFormPopupState(JSON.parse(sender))

                                            if(JSON.parse(sender)?.[0]?.lookUpEntities?.length > 0){
                                                
                                                httpRequest('POST', requestsApi.getLookupTable, requestsApi.basicAuth, {
                                                    "lookUpEntities": JSON.parse(sender)?.[0]?.lookUpEntities
                                                })
                                                .then((sender) => {
                                                    setDataLookupRecordFormPopupState(JSON.parse(sender))

                                                    setKeyFieldNameState('_cadastro_id')
                                                    setIDValueDetailEntity(null)
                                                    setDetailEntityName("_cadastro_emails")
                                                    // setDetailCaptionName(senderDetailEntity.caption)
                                                    setPopupRecordFormOpen(true)
                                                    setPopupState(true)
                                                    setStateModeEdit(true)
                                                })
                                                .catch((error) => {
                                                    setMessage(error.data.error)
                                                    setVisible(true)
                                                })
                                            }
                                            else{
                                                setKeyFieldNameState('_cadastro_id')
                                                setIDValueDetailEntity(null)
                                                setDetailEntityName("_cadastro_emails")
                                                // setDetailCaptionName(senderDetailEntity.caption)
                                                setPopupRecordFormOpen(true)
                                                setPopupState(true)
                                                setStateModeEdit(true)
                                            }
                                        })
                                        .catch((error) => {
                                            setMessage(error.data.error)
                                            setVisible(true)
                                        })
                                    }
                                }
                                }
                            />
                        </div>

                        <div className="buttons-tabpanel">
                            <Button
                                icon="rowproperties"
                                hint="Editar registros em lote no grid"
                                type="normal"
                                visible={permissoesEmailsState ? permissoesEmailsState?._edit: false}
                                onClick={() => {
                                    setModeEditDataGridEmailsState(!modeEditDataGridEmailsState)
                                }}
                            />
                        </div>

                        <div className="buttons-tabpanel">
                            <Button
                                icon="refresh"
                                hint="Atualizar dados do servidor"
                                type="normal"
                                onClick={() => refreshDetailEntity("_cadastro_emails")}
                            />
                        </div>
                    </div>

                    <DataGrid
                        ref={refDataGridConsultaBoletoFormEmails}
                        dataSource={emailsDataSourceState}
                        columnAutoWidth={true}
                        showBorders={true}
                        onRowInserting={onRowInsertingEmail}
                        onRowRemoved={onRowRemovedEmail}
                        onSaving={handleBatchSaveEmail} 
                        onSelectionChanged={(sender) => {
                            // console.log(sender.component.getSelectedRowsData())
                            setEmailState(sender.component.getSelectedRowsData())
                        }}
                    >
                        <Selection 
                            mode="multiple"
                            keyExpr='ID'
                        />
                        <Scrolling 
                            rowRenderingMode='infinity'
                            showScrollbar='always'
                            >
                        </Scrolling>

                        <Paging defaultPageSize={6} />

                        <Sorting
                            mode="single"
                        />
                        
                        <Pager
                            visible={true}
                            // allowedPageSizes={10}
                            // showPageSizeSelector={true}
                            showInfo={true}
                            showNavigationButtons={true} 
                        />

                        <HeaderFilter
                            allowSearch={true}
                            visible={true}
                        />

                        <Editing
                            allowDeleting={modeEditDataGridEmailsState && permissoesEmailsState?._delete}
                            allowUpdating={modeEditDataGridEmailsState && permissoesEmailsState?._edit}
                            // allowDeleting={modeEditDataGridEmailsState}
                            // allowUpdating={modeEditDataGridEmailsState}
                            // allowAdding={modeEditDataGridEmailsState && permissoesEmailsState?._insert}
                            confirmDelete={true}
                            mode="batch"
                            useIcons={true}
                        //selectTextOnEditStart={selectTextOnEditStart}
                        //startEditAction={startEditAction}
                        />
                        <Column
                            caption="Deletar"
                            visible={modeEditDataGridEmailsState && permissoesEmailsState?._delete}
                            // visible={modeEditDataGridEmailsState}
                            type="buttons"
                            width={110}
                        >
                            <Button
                                name="delete"
                            />
                        </Column>

                        <Column
                            dataField="_cryptemail"
                            caption="E-mail"
                        ></Column>

                    </DataGrid>
                </div> 
            </div>



            <div style={{display: "flex"}}>
                <div className="div-grupos-gerar-acordo"> 
                    <TabPanel>
                        <TabItem title="Sms">
                            <div className="mensagem-custom-form-div-enviar">
                                <SelectBox
                                    dataSource={dataLookupState?._templatesms}
                                    displayExpr={"_descricao"}
                                    valueExpr={"id"}
                                    width={500}
                                    onValueChanged = {(sender) => {
                                        setTemplateSelecionadoSmsState(sender.value)
                                    }}
                                />
                                <Button
                                    style={{marginLeft: "1em"}}
                                    text="Enviar"
                                    type="default"
                                    stylingMode="contained"
                                    onClick={() => {
                                        enviarMensagem("_enviarTemplateSmsContato", templateSelecionadoSmsState)
                                    }}
                                />
                            </div>
                        </TabItem>
                    

                    
                        <TabItem title="WhatsApp">
                            <div className="mensagem-custom-form-div-enviar">
                                <SelectBox
                                    dataSource={dataLookupState?._templatewhatsapp}
                                    displayExpr={"_descricao"}
                                    valueExpr={"id"}
                                    width={500}
                                    onValueChanged = {(sender) => {
                                        setTemplateSelecionadoWhatsappState(sender.value)
                                    }}
                                />

                                <Button
                                    style={{marginLeft: "1em"}}
                                    text="Enviar"
                                    type="default"
                                    stylingMode="contained"
                                    onClick={() => {
                                        enviarMensagem("_enviarTemplateWhatsappContato", templateSelecionadoWhatsappState)
                                    }}
                                />
                            </div>
                        </TabItem>
                    </TabPanel>
                </div>
            
                <div className="div-grupos-gerar-acordo"> 
                    <TabPanel>
                        <TabItem title="E-mail">
                            <div className="mensagem-custom-form-div-enviar">
                                <SelectBox
                                    dataSource={dataLookupState?._templateemail}
                                    displayExpr={"_descricao"}
                                    valueExpr={"id"}
                                    width={500}
                                    onValueChanged = {(sender) => {
                                        setTemplateSelecionadoEmailState(sender.value)
                                    }}
                                />

                                <Button
                                    style={{marginLeft: "1em"}}
                                    text="Enviar"
                                    type="default"
                                    stylingMode="contained"
                                    onClick={() => {
                                        enviarEmail("_enviarTemplateEmailContato", templateSelecionadoEmailState)
                                    }}
                                />
                            </div>
                        </TabItem>
                    </TabPanel>
                </div>
            </div>

            <h4 style={{marginBottom: "0.5em"}}> Últimas mensagens</h4>
            <DataGrid
                // style={{marginTop: "3em"}}
                dataSource={logMensagensState}
                columnAutoWidth={true}
                showBorders={true}
                wordWrapEnabled={true}
                allowColumnResizing={true}
            >

                <Selection 
                    mode="single"
                    keyExpr='ID'
                />

                <Scrolling 
                    rowRenderingMode='infinity'
                    showScrollbar='always'
                >
                </Scrolling>

                <Paging defaultPageSize={6} />

                <Sorting
                    mode="single"
                />
                
                <Pager
                    visible={true}
                    // allowedPageSizes={10}
                    // showPageSizeSelector={true}
                    showInfo={true}
                    showNavigationButtons={true} 
                />

                <HeaderFilter
                    allowSearch={true}
                    visible={true}
                />

                <Column
                    dataField="data"
                    caption="Data"
                    dataType={"date"}
                    format="shortDateShortTime"
                ></Column>

                <Column
                    width={500}
                    dataField="texto"
                    caption="Texto"
                ></Column>

                <Column
                    dataField="fone"
                    caption="Telefone"
                ></Column>

                <Column
                    dataField="ocorrencia"
                    caption="Ocorrência"
                ></Column>

                <Column
                    dataField="funcionario"
                    caption="Funcionário"
                ></Column>

            </DataGrid>
        </>
    )
}

export default MensagemCustomForm;