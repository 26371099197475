import {useState, useEffect, memo, useCallback} from "react"
import { useRequestsApi } from "../../Context/RequestsApi"
import RightTabPanelTeleCob from './RightTabPanelTeleCob';
import MultiViewMainTeleCob from './MultiViewMainTeleCob';
import { applicationID, relationId, equipeId, URLLogin, ramal, URLLoginAbrirPopup } from "../../api/ApplicationID";
import { httpRequest } from "../../functions";
import {useProximaFichaDados} from "../../Context/ProximaFichaDados"
import {useMainMenuJson} from "../../Context/MainMenuJson"
import UpfPopupTelecob from "../../CardComponents/UpfPopupTelecob";
import WelcomeTab from "../DrawerMain/TabPanelMain/WelcomeTab";
import UpfPopupMessage from "../../CardComponents/UpfPopupMessage";
import DrawerVariant from "../DrawerMain/DrawerVariant";
import {useBackendJson} from "../../Context/BackendJson"
import { usePausasDisponiveisGlobalState } from "../../Context/PausasDisponiveisGlobalState"
import { useDiscadorEmAtendimentoGlobalState } from "../../Context/DiscadorEmAtendimentoGlobalState";
import { usePausaEscolhidaDadosGlobalState } from "../../Context/PausaEscolhidaDadosGlobalState";
import ScrollView from 'devextreme-react/scroll-view';
import QueryForm from '../QueryForm';
import RecordForm from "../RecordForm"
import ViewForm from "../ViewForm";
import GerarAcordoForm from "./GerarAcordoForm";
import GerarBoletoForm from "./GerarBoletoForm";
import { Toast } from 'devextreme-react/toast';
import TabPanel, {Item} from "devextreme-react/tab-panel";
import { 
    Popup, 
    ToolbarItem,
} from 'devextreme-react/popup';
import { SpeedDialAction } from "devextreme-react";
import { validatePermission } from "../../functions/validatePermission";
import DashboardDesignerForm from "../DashBoardsComponents/DashboardDesignerForm";
import DashboardViewerForm from "../DashBoardsComponents/DashboardViewerForm";
import FileManagerForm from "../FileManagerForm";
import OcorrenciaCustomForm from "../OcorrenciaCustomForm";
import OcorrenciaTarefaCustomForm from "../OcorrenciaTarefaCustomForm";
import HistoricoCustomForm from "../HistoricoCustomForm";
import QueryFormPopup from "../QueryForm/QueryFormPopup";
import { useLoadPanelVisibleGlobalState } from "../../Context/LoadPanelVisibleGlobalState";
import { useWidgetsTeleCob } from "../../Context/WidgetsTeleCob";
import TelecobrancaForm from "./TelecobrancaForm";
import { useTabPanelItems } from "../../Context/TabPanelItems";
import { useIndexTabPanelMain } from "../../Context/IndexTabPanelMain";
import { useUpfFloatPopupMessageVisibleGlobalState } from "../../Context/UpfFloatPopupMessageVisibleGlobalState";
import { useTelecobTabPanelItemsGlobalState } from "../../Context/TelecobTabPanelItemsGlobalState";
import { useTelecobIndexTabPanelGlobalState } from "../../Context/TelecobIndexTabPanelGlobalState";
import { IoLogoWhatsapp } from "react-icons/io5";
import './style.css'

sessionStorage.setItem("tabPanelMainIndex", 0)

const TeleCob = (props) => {

    const {upfFloatPopupMessageVisibleGlobalState, setUpfFloatPopupMessageVisibleGlobalState} = useUpfFloatPopupMessageVisibleGlobalState()
    const {telecobTabPanelItemsGlobalState, setTelecobTabPanelItemsGlobalState} = useTelecobTabPanelItemsGlobalState()
    const {telecobIndexTabPanelGlobalState, setTelecobIndexTabPanelGlobalState} = useTelecobIndexTabPanelGlobalState()

    const closeButtonHandler = useCallback((item) => {
        const Handle = [...telecobTabPanelItemsGlobalState];
        const index = Handle.indexOf(item);
    
        Handle.splice(index, 1);
        setTelecobTabPanelItemsGlobalState(Handle);

        if(telecobTabPanelItemsGlobalState.length !== 0){
            setTelecobIndexTabPanelGlobalState(telecobTabPanelItemsGlobalState.length -2)
        }
      },[telecobTabPanelItemsGlobalState])

      const titleTabPanel = useCallback((item) => {
        const closeHandler = () => {
            closeButtonHandler(item);
        }
        if(item.title === "Discador") {
            return (
                <div className="tab-index">
                    <i
                        className={`dx-icon icon-tabtreeview dx-icon-${item.icon}`} 
                    />
                    <span className="span-text">
                        {item.title}
                    </span>
                </div>
            )
        }
        else if(item.icon === "WhatsAppCustomForm"){
            return (
                <div className="tab-index">
                    {/* <i
                        className={`dx-icon icon-tabtreeview dx-icon-${item.icon}`} 
                    /> */}

                    <IoLogoWhatsapp className="dx-icon icon-tabtreeview" />

                    <span className="span-text">
                        {item.title}
                    </span>
                        <i 
                            className="dx-icon  icon-close dx-icon-close" 
                            onClick={closeHandler}
                        />
                </div>
            )
        }
        else {
            return (
                <div className="tab-index">
                    <i
                        className={`dx-icon icon-tabtreeview dx-icon-${item.icon}`} 
                    />
                    <span className="span-text">
                        {item.title}
                    </span>
                        <i 
                            className="dx-icon  icon-close dx-icon-close" 
                            onClick={closeHandler}
                        />
                </div>
            )
        }
    },[closeButtonHandler])

    const contentTab = (sender) =>{
        return(
            <>
                {sender.component}
            </>
        )
    }


    const permissionSubitems = (array) => {
        for(let i = 0; i <= array.length - 1; i++){
            let permissoesSubItem = validatePermission(array[i]?._objectId)
            if(permissoesSubItem?._show !== 1){
                array.splice(i,1)
            }
            else{
                if(array[i]?.items?.length > 0){
                    permissionSubitems(array[i]?.items)
                }
                if(array[i]?.subMenuItems?.length > 0){
                    permissionSubitems(array[i]?.subMenuItems)
                }
            }
        }
    }

    window.onunload = function () {
        sessionStorage.clear();
    }

    
    const { tabPanelItems, setTabPanelItems } = useTabPanelItems();

    const {indexTabPanelMain, setIndexTabPanelMain} = useIndexTabPanelMain();

    const optionChangeMultiViewMainTeleCob = (sender)=>{
        if(sender?.name){
            if(sender.name === "selectedIndex"){
            sessionStorage.setItem("tabPanelMainIndex", sender.value)
            setIndexTabPanelMain(parseInt(sessionStorage.getItem("tabPanelMainIndex")))
            }
        }
        else if(sender) {
            sessionStorage.setItem("tabPanelMainIndex", sender)
            console.log(parseInt(sessionStorage.getItem("tabPanelMainIndex")))
            setIndexTabPanelMain(parseInt(sessionStorage.getItem("tabPanelMainIndex")))
        }
    }

    // useEffect(() => {
    //     // console.log("mudou global dos componentes, logo vai mudar local do indice")
    //     optionChange(tabPanelItems.length - 1, setIndexTabPanelMain) 
    
    // }, [tabPanelItems])
    
    // useEffect(() => {
    //     // console.log("mudou indice, logo vai mudar global do indice depois da troca de abas")
    //     dispatch(changeTabIndice(indexTabPanelMain))
    
    // }, [indexTabPanelMain])



    const {requestsApi} = useRequestsApi()
    const {proximaFichaDados, setProximaFichaDados} = useProximaFichaDados()
    const {mainMenuJson, setMainMenuJson} = useMainMenuJson()
    const {pausasDisponiveisGlobalState, setPausasDisponiveisGlobalState} = usePausasDisponiveisGlobalState()
    const {discadorEmAtendimentoGlobalState, setDiscadorEmAtendimentoGlobalState} = useDiscadorEmAtendimentoGlobalState()
    const {backendJson, setBackendJson} = useBackendJson()
    const {pausaEscolhidaDadosGlobalState, setPausaEscolhidaDadosGlobalState} = usePausaEscolhidaDadosGlobalState()

    const [queryFormPopupState, setQueryFormPopupState] = useState(false)
    const [gravouOcorrencia, setGravouOcorrencia] = useState(true)
    const [foreignFieldSelectedItem, setForeignFieldSelectedItem] = useState()
    const [queryFormPopupForeignFieldState, setQueryFormPopupForeignFieldState] = useState(true)
    const [itemClicadoState, setItemClicadoState] = useState();
    // const [contratosClientesDataSource, setContratosClientesDataSource] = useState([]);
    const [popupQueryFormOpen, setPopupQueryFormOpen] = useState(false);
    const {loadPanelVisibleGlobalState, setLoadPanelVisibleGlobalState} = useLoadPanelVisibleGlobalState()
    const {widgetsTeleCob, setWidgetsTeleCob} = useWidgetsTeleCob()
    const [outrosContratosDataSource, setOutrosContratosDataSource] = useState([]);

    const popups = {
        isVisible: false,
        message: 'Houve um erro',
    };
    const [popup, setPopup] = useState(popups);

    const hidePopup = useCallback(() =>{
        setPopup({
            ...popup,
            isVisible: false,
        })
    },[popup]);

    const closeButtonPopup = {
        text: 'Ok'
        ,icon: 'arrowright'
        ,onClick: hidePopup
    };

    const [states, setStates] = useState(
        {
        opened: true,
        openedStateMode: "shrink",
        revealMode: "slide",
        position: "left",
        // rightTabPanelVisible: false,
        mode: null,
        iconButtonRightPanel: "chevrondoubleright",
        // tituloId: null,
        templateTelecobrancaId: null
    })

    const [rightTabPanelVisible, setRightTabPanelVisible] = useState(false)

    const [statesToast, setStatesToast] = useState({
        toastVisible: false,
        toastType: "info",
        toastMessage: "",
    })

    const toastConfiguration = (visible, type, message) => {
        setStatesToast({...statesToast, 
            toastVisible: visible,
            toastType: type,
            toastMessage: message
        })
    }

    const onHidingToast = () => {
        setStatesToast({...states, toastVisible: false})
    }


    //estado e função do UpfPopupMessage
    const [popupErrorVisibleState, setPopupErrorVisibleState] = useState(false)
    const [popupErrorMessageState, setPopupErrorMessageState] = useState("")

    const hidePopupError = () => {
        setPopupErrorVisibleState(false)
    }


    useEffect(() => {
        console.log("useEffect")
        if(URLLogin){
            console.log(URLLogin)
            if(URLLoginAbrirPopup === 1){
                window.open(`${URLLogin}`, "nome do popup", "width=340,height=600,left=10000,top=100")
            }
            else{
                httpRequest('GET', `${URLLogin}`, requestsApi.basicAuth)
                .then((sender) => {
                    // const resultGetMensagensTituloJson = JSON.parse(sender)
                    // setLogMensagensState(resultGetMensagensTituloJson)
                    console.log(sender)
                })
                .catch((err) => {
                    console.log(err)
                    setPopupErrorMessageState(err?.data?.err)
                    setPopupErrorVisibleState(true)
                })
            }
        }
        if(requestsApi && !rightTabPanelVisible) {
            setRightTabPanelVisible(true)
        }
        if(requestsApi) {
            httpRequest("POST", requestsApi.deleteRecordFromJson, requestsApi.basicAuth, {
                applicationId: applicationID,
                entityName: "_tituloemtelecobranca",
                fieldName: "_funcionarioid", 
                expressao: relationId,
                relationId: relationId
            })
            .then((sender) => console.log(sender))
            .catch((err) => {
                console.log(err)
                setPopupErrorMessageState(err?.data?.err)
                setPopupErrorVisibleState(true)
            })

            httpRequest('POST', requestsApi.getPausasDisponiveis, requestsApi.basicAuth, {
                applicationId: applicationID,
                equipeId: equipeId
            })
            .then((sender) => {
                let getPausasDisponiveisResultJson = JSON.parse(sender)

                //remove pausa atendimento pelo id 06B6D084A2E94188A06376BF6D9517BF
                if(getPausasDisponiveisResultJson.find(e => e?.id === "06B6D084A2E94188A06376BF6D9517BF")){
                    getPausasDisponiveisResultJson.splice(
                        getPausasDisponiveisResultJson.indexOf(
                            getPausasDisponiveisResultJson.find(e => e?.id === "06B6D084A2E94188A06376BF6D9517BF")
                        ), 1
                    )
                }


                let newGetPausasDisponiveisResultJson = getPausasDisponiveisResultJson.filter((a, b) => getPausasDisponiveisResultJson.indexOf(a) === b)
                

                for(let c = 0; c <= newGetPausasDisponiveisResultJson.length - 1; c++){
                    newGetPausasDisponiveisResultJson[c]["text"] = newGetPausasDisponiveisResultJson[c]._descricao
                    newGetPausasDisponiveisResultJson[c]["path"] = "pausa"
                }


                setPausasDisponiveisGlobalState(newGetPausasDisponiveisResultJson)

                const menuItemsPermission = JSON.parse(sessionStorage.getItem('MenuDataSource'))

                const arrayResult = [
                    {
                        text : "Pausas",
                        path : null,
                        icon : "folder",
                        entityName: null,
                        badge : null,
                        parameters : null,
                        permissionName : null,
                        items : getPausasDisponiveisResultJson
                    }
                ]

                let indiceArrayResult = 0

                for(let c = 0; c <= menuItemsPermission[0].mainMenu[0].items?.length - 1; c++){
                    arrayResult.push(menuItemsPermission[0].mainMenu[0].items[c])

                    // let permissoesItem = validatePermission(menuItemsPermission[0].mainMenu[0].items[c]?._objectId)
                    
                    // if(permissoesItem?._show === 1){
                    //     arrayResult.push(menuItemsPermission[0].mainMenu[0].items[c])
                    //     indiceArrayResult++
                    //     if(arrayResult[indiceArrayResult - 1]?.items?.length > 0){

                    //         permissionSubitems(arrayResult[indiceArrayResult - 1]?.items)
                            
                    //     }
                    // }
                }


                setMainMenuJson([...mainMenuJson, ...arrayResult])
            })
            .catch((err) => {
                console.log(err)
                setPopupErrorMessageState(err?.data?.err)
                setPopupErrorVisibleState(true)
            })
        }
        setTelecobTabPanelItemsGlobalState([
            {
                ...telecobTabPanelItemsGlobalState,
                title: `Discador`
                , icon: "tel"
                , badge: ""
                , id : parseInt(sessionStorage.getItem("idTabSelecionada"))
                , component: <MultiViewMainTeleCob 
                        className="tabpanel-main-telecob"
                        idTab={parseInt(sessionStorage.getItem("idTabSelecionada"))}
                    />
                , text: Math.random()
            }
        ])

    }, [])

    const carregarTelecobrancaForm = (resultGetDataTelecobrancaJson, getProximaFichaResult, abrirFichaDiscador) => {
        //recebe o array de estruturas
        //pega valores do getDataTelecobranca do _configuracaoCredor
        const tituloId = resultGetDataTelecobrancaJson[0].masterEntity[0].records[0].id
        const templateTelecobrancaId = resultGetDataTelecobrancaJson?.[0]?.masterEntity?.[0]?.records?.[0]?.["_templatetelecobrancaid"]
        const dataEntityCadastro = resultGetDataTelecobrancaJson.find(e => e?.masterEntity?.[0]?.entityName === "_cadastro")

        setIndexTabPanelMain(tabPanelItems.length)

        if(getProximaFichaResult?.tituloId){  

            setTabPanelItems([
                ...tabPanelItems,
                {
                    className: "tab-sheet"
                    //,icon: '/images/favicon.ico'
                    ,component: 
                        <ScrollView  width='100%' height='100%' useNative={true}>
                            <TelecobrancaForm 
                                id={Math.random()} 
                                dataEntity={resultGetDataTelecobrancaJson}
                                templateId={templateTelecobrancaId}
                                tituloId={getProximaFichaResult.tituloId}
                                IDValue={tituloId}
                                abrirFichaDiscador={abrirFichaDiscador}
                                setStates={setStates}                                                           
                                // entityName="titulo"
                                // modeEditOn={false} 
                            />
                        </ScrollView>
                    ,text: Math.random()
                    ,type: "telecobrancaForm"
                }
            ])     
          
            setProximaFichaDados({...proximaFichaDados,
                tituloId: getProximaFichaResult.tituloId,
                credorId: resultGetDataTelecobrancaJson[0].masterEntity[0].records[0]._credorid,
                clienteId: dataEntityCadastro?.masterEntity?.[0]?.records?.[0]?.id,
                dataEntityCadastro: dataEntityCadastro,
                nomeFilaAtual: getProximaFichaResult.nomeFilaAtual,
                nomeRegraAtual: getProximaFichaResult.nomeRegraAtual
            })

            setLoadPanelVisibleGlobalState(false)
        } else if (getProximaFichaResult){
            setTabPanelItems([
                ...tabPanelItems,
                {
                    className: "tab-sheet"
                    //,icon: '/images/favicon.ico'
                    ,component: 
                        <ScrollView  width='100%' height='100%' useNative={true}>
                            <TelecobrancaForm 
                                id={Math.random()} 
                                dataEntity={resultGetDataTelecobrancaJson}
                                templateId={templateTelecobrancaId}
                                tituloId={getProximaFichaResult}
                                IDValue={getProximaFichaResult}
                                abrirFichaDiscador={abrirFichaDiscador}
                                setStates={setStates}
                                // entityName="titulo"
                                // modeEditOn={false} 
                            />
                        </ScrollView>
                    ,text: Math.random()
                    ,type: "telecobrancaForm"
                }
            ])

            setProximaFichaDados({...proximaFichaDados,
                tituloId: getProximaFichaResult,
                credorId: resultGetDataTelecobrancaJson[0].masterEntity[0].records[0]._credorid,
                clienteId: dataEntityCadastro?.masterEntity?.[0]?.records?.[0]?.id,
                dataEntityCadastro: dataEntityCadastro,
                nomeFilaAtual: null,
                nomeRegraAtual: null
            })

            setLoadPanelVisibleGlobalState(false)
        }
    }

    const getClientContracts = () => {
        const dataSourceFichaAtual = JSON.parse(sessionStorage.getItem("dataSourceFichaAtual"));
        const exibirOutrosContratos = JSON.parse(sessionStorage.getItem("MenuDataSource"))[0].exibirOutrosContratos;

        return new Promise((resolve, _) => {
            if (dataSourceFichaAtual) {
                const filterRequestData = [];

                if (exibirOutrosContratos == 2 && dataSourceFichaAtual._qtdoutroscontratoscliente > 0) {
                    filterRequestData.push(
                        { 
                            fieldName: "_clienteid", 
                            expressao: dataSourceFichaAtual._clienteId 
                        },
                        { 
                            fieldName: "_credorid", 
                            expressao: dataSourceFichaAtual.credorId 
                        }
                    )
                } else if (exibirOutrosContratos == 1 && dataSourceFichaAtual._qtdoutroscontratosclientecredoratual > 0) {
                    filterRequestData.push({ 
                        fieldName: "_clienteid", 
                        expressao: dataSourceFichaAtual._clienteId 
                    });
                } else {
                    const removeOutrosContratosMenu = mainMenuJson.filter(menu => menu.text.toLowerCase() !== "outros contratos");                    
                    setMainMenuJson(removeOutrosContratosMenu);
                }
                
                httpRequest("POST", requestsApi.getDataEntityArraySearch, requestsApi.basicAuth, {
                    "entityName": "_titulo",                        
                    "filter": filterRequestData,
                    "applicationId": applicationID,
                })
                .then((sender) => {
                    const senderInsertRecordFromJson = JSON.parse(sender);
                    resolve(senderInsertRecordFromJson.masterEntity[0].records);
                })
                .catch((error) => {
                    console.log(error);
                })
            }
        })
    }

    const abrirFicha = (getProximaFichaResult) => {
        if (!states.tabulacaoAcionamentoJson?.[0]){
            states["tabulacaoAcionamentoJson"] = [{tituloId : getProximaFichaResult.tituloId}]
        } else {
            states.tabulacaoAcionamentoJson[0]["tituloId"] = getProximaFichaResult.tituloId
        }

        let tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
        const horarioInicio = (new Date(Date.now() - tzoffset)).toISOString().slice(0, -1);

        sessionStorage.setItem("dataSourceFichaAtual", JSON.stringify(getProximaFichaResult))
        sessionStorage.setItem("horarioInicio", horarioInicio.toLocaleString())
        
        setProximaFichaDados({...proximaFichaDados,
            tituloId: getProximaFichaResult.tituloId,
            credorId: getProximaFichaResult.credorId,
            clienteId: getProximaFichaResult._clienteId,
            _qtdoutroscontratoscliente: getProximaFichaResult._qtdoutroscontratoscliente,
            _qtdoutroscontratosclientecredoratual: getProximaFichaResult._qtdoutroscontratosclientecredoratual
        })

        //chamar o getDataTelecobranca para pegar o credorId
        if(getProximaFichaResult.tituloId.length > 0){
            httpRequest("POST", requestsApi.getDataTelecobranca, requestsApi.basicAuth, {
                applicationId: applicationID,
                tituloId: getProximaFichaResult.tituloId
            })
            .then((sender) => {
                const resultGetDataTelecobrancaJson = JSON.parse(sender)

                //carregar mensagens
                httpRequest("POST", requestsApi.getTemplateMensagemTitulo, requestsApi.basicAuth, {
                    applicationId: applicationID,
                    tituloId: getProximaFichaResult.tituloId
                })
                .then((sender) => {
                    sessionStorage.setItem("arrayTemplateMensagemTitulo", sender)
                    // setUpfFloatPopupMessageVisibleGlobalState({
                    //     visible: true,
                    //     fixo: true,
                    //     mensagemId: null,
                    //     actionName: "abrirFicha"
                    // })
                })
                .catch((error) => {
                    setLoadPanelVisibleGlobalState(false)
                    console.log(error) 
                    if(error?.data?.error){
                        setStates({...states, 
                            isVisiblePopupError: true,
                            messagePopupError: error.data.error
                        })
                    }
                    else{
                        setStates({...states, 
                            isVisiblePopupError: true,
                            messagePopupError: "Ocorreu um erro no getDataTelecobranca!"
                        })
                    }
                })
                
                if(getProximaFichaResult.credorId){
    
                    httpRequest("POST", requestsApi.getWidget, requestsApi.basicAuth, {
                        applicationId: applicationID,
                        equipeId: equipeId,
                        credorId: getProximaFichaResult.credorId
                    })
                    .then((sender) => {
                        const getWidgetsResult = JSON.parse(sender)
                        setWidgetsTeleCob(getWidgetsResult)
                        setGravouOcorrencia(false)
                    })
                    .catch((error) => {
                        if(error?.data?.error){
                            setStates({...states, 
                                isVisiblePopupError: true,
                                messagePopupError: error.data.error
                            })
                        }
                        else{
                            setStates({...states, 
                                isVisiblePopupError: true,
                                messagePopupError: "Ocorreu um erro no getWidget!"
                            })
                        }
                    })
                }
                carregarTelecobrancaForm(resultGetDataTelecobrancaJson, getProximaFichaResult)
            })
            .catch((error) => {
                setLoadPanelVisibleGlobalState(false)
                console.log(error) 
                if(error?.data?.error){
                    setStates({...states, 
                        isVisiblePopupError: true,
                        messagePopupError: error.data.error
                    })
                }
                else{
                    setStates({...states, 
                        isVisiblePopupError: true,
                        messagePopupError: "Ocorreu um erro no getDataTelecobranca!"
                    })
                }
            })
        } else if (getProximaFichaResult.nomeFilaAtual === "Sem fichas"){
            setStates({...states, 
                isVisiblePopupError: true,
                messagePopupError: "Sem fichas."
            })
        }
    }

    const [dadosFichasArrayState, setDadosFichasArrayState] = useState([])

    useEffect(() => {
        if(dadosFichasArrayState.find((e) => e.id === parseInt(sessionStorage.getItem("idTabSelecionada")))){
            setProximaFichaDados(dadosFichasArrayState.find((e) => e.id === parseInt(sessionStorage.getItem("idTabSelecionada"))).fichaDataSource)
        }
        
    }, [telecobIndexTabPanelGlobalState])

    useEffect(() => {
        if(!dadosFichasArrayState.find((e) => e.id === parseInt(sessionStorage.getItem("idTabSelecionada"))) && proximaFichaDados?.tituloId){
            setDadosFichasArrayState(
                [...dadosFichasArrayState, 
                    {
                        fichaDataSource: proximaFichaDados, 
                        id: parseInt(sessionStorage.getItem("idTabSelecionada"))
                    }
                ]
            )
        }
        console.log(proximaFichaDados)
        console.log(dadosFichasArrayState)
    }, [proximaFichaDados])

    const addNewTab = useCallback((itemClicado) => {
        let tabItemId = Math.random()
        console.log(tabPanelItems)
        console.log(itemClicado)
        let temAcordo = tabPanelItems.find((item) => {return item["type"] === 'gerarAcordoForm'})
        
        let temBoleto = tabPanelItems.find((item) => {return item["type"] === 'boletoForm'})

        //se tiver acordo ou boleto deletar do array
        if(temAcordo){
            setIndexTabPanelMain(tabPanelItems.indexOf(temAcordo) - 1)
            tabPanelItems.splice(tabPanelItems.indexOf(temAcordo), 1)
        }

        if(temBoleto){
            setIndexTabPanelMain(tabPanelItems.indexOf(temBoleto) - 1)
            tabPanelItems.splice(tabPanelItems.indexOf(temAcordo), 1)
        }

        if(itemClicado.itemData.path !== null && proximaFichaDados.tituloId !== null
            && proximaFichaDados.tituloId.length > 0
            && itemClicado.itemData.path === 'telecobrancaForm'){
            //checando se ja existe a tab
            if(tabPanelItems.some((item) => { return item["type"] === 'telecobrancaForm'})) {
                let element = tabPanelItems.find((item) => {return item["type"] === 'telecobrancaForm'})// retorna o elemento
                setIndexTabPanelMain(tabPanelItems.indexOf(element))//retorna o indice do elemento
            } 
            // else {
            //     setTabPanelItems([
            //         ...tabPanelItems,
            //         {
            //              title: itemClicado.itemData.text
            //             ,icon: itemClicado.itemData.icon
            //             ,className: "tab-sheet"
            //             //,icon: '/images/favicon.ico'
            //             ,component: <ScrollView  width='100%' height='100%'>
            //                             <TelecobrancaForm 
            //                                 id={Math.random()} 
            //                                 templateId={proximaFichaDados.templateTelecobrancaId}
            //                                 // entityName="titulo"
            //                                 tituloId={proximaFichaDados.tituloId}
            //                                 // modeEditOn={false} 
            //                                 IDValue={proximaFichaDados.tituloId}
            //                             />
            //                         </ScrollView>
            //             ,text: Math.random()
            //             ,type: itemClicado.itemData.path
            //         }
            //     ])
            //     setIndexTabPanelMain(0)
            // }
        }
        
        if(itemClicado.itemData.path === 'tarefasForm'){
            setIndexTabPanelMain(0)
        }

        else if (itemClicado.itemData.path !== null && itemClicado.itemData.path === 'queryForm') {

            if(!tabPanelItems.find((item) => {return item.title === itemClicado.itemData.text})){
                setTabPanelItems([
                    ...tabPanelItems,
                    {
                        title: itemClicado.itemData.text
                        , icon: itemClicado.itemData.icon
                        , className: "tab-sheet"
                        ,tipoFormularioTelecob: itemClicado.itemData.path
                        , component:
                            <ScrollView width='100%' height='100%' useNative={true}>
                                <QueryForm
                                    entityName={itemClicado.itemData.parameters}
                                    tabItemId={tabItemId}
                                    caption={itemClicado.itemData.text}
                                    formularioTelecob={true}
                                />
                            </ScrollView>
                        , text: Math.random()
                    }
                ])
                setIndexTabPanelMain(tabPanelItems.length)
            }
            else{
                let element = tabPanelItems.find((item) => {return item.title === itemClicado.itemData.text})// retorna o elemento
                setIndexTabPanelMain(tabPanelItems.indexOf(element))
            } 
        }

        else if (itemClicado.itemData.path !== null && itemClicado.itemData.path === 'dashboardDesignerForm') {

            if(!tabPanelItems.find((item) => {return item.title === itemClicado.itemData.text})){
                setTabPanelItems([
                    ...tabPanelItems,
                    {
                        title: itemClicado.itemData.text
                        , icon: itemClicado.itemData.icon
                        , component:
                            <ScrollView width='100%' height='100%' useNative={true}>
                                <DashboardDesignerForm />
                            </ScrollView>
                        , text: Math.random()
                    }
                ])
                setIndexTabPanelMain(tabPanelItems.length)
            }
            else{
                let element = tabPanelItems.find((item) => {return item.title === itemClicado.itemData.text})// retorna o elemento
                setIndexTabPanelMain(tabPanelItems.indexOf(element))
            } 
        }

        else if (itemClicado.itemData.path !== null && itemClicado.itemData.path === 'dashboardViwerForm') {

            if(!tabPanelItems.find((item) => {return item.title === itemClicado.itemData.text})){
                setTabPanelItems([
                    ...tabPanelItems,
                    {
                        title: itemClicado.itemData.text
                        , icon: itemClicado.itemData.icon
                        , component:
                            <ScrollView width='100%' height='100%' useNative={true}>
                                <DashboardViewerForm
                                    dashboardId={itemClicado.itemData.parameters}
                                />
                            </ScrollView>
                        , text: Math.random()
                    }
                ])
                setIndexTabPanelMain(tabPanelItems.length)
            }
            else{
                let element = tabPanelItems.find((item) => {return item.title === itemClicado.itemData.text})// retorna o elemento
                setIndexTabPanelMain(tabPanelItems.indexOf(element))
            } 
        }

        else if (itemClicado.itemData.path !== null && itemClicado.itemData.path === 'fileManagerForm') {

            if(!tabPanelItems.find((item) => {return item.title === itemClicado.itemData.text})){
                setTabPanelItems([
                    ...tabPanelItems,
                    {
                        title: itemClicado.itemData.text
                        , icon: itemClicado.itemData.icon
                        , component:
                            <ScrollView width='100%' height='100%' useNative={true}>
                                <FileManagerForm
                                    path={itemClicado.itemData.parameters}
                                    caption={itemClicado.itemData.text}
                                />
                            </ScrollView>
                        , text: Math.random()
                    }
                ])
                setIndexTabPanelMain(tabPanelItems.length)
            }
            else{
                let element = tabPanelItems.find((item) => {return item.title === itemClicado.itemData.text})// retorna o elemento
                setIndexTabPanelMain(tabPanelItems.indexOf(element))
            } 
        }

        else if (itemClicado.itemData.path !== null && itemClicado.itemData.path === 'ocorrenciaCustomForm') {

            if(!tabPanelItems.find((item) => {return item.title === itemClicado.itemData.text})){
                setTabPanelItems([
                    ...tabPanelItems,
                    {
                        title: itemClicado.itemData.text
                        , icon: itemClicado.itemData.icon
                        , component:
                            <ScrollView width="100%" height="100%" useNative={true}>
                                <OcorrenciaCustomForm 
                                    parameters={itemClicado.itemData?.parameters}
                                />
                            </ScrollView>
                        , text: Math.random()
                    }
                ])
                setIndexTabPanelMain(tabPanelItems.length)
                // setUpfFloatPopupMessageVisibleGlobalState({
                //     visible: true,
                //     fixo: true,
                //     mensagemId: null,
                //     actionName: "abrirTelaResposta"
                // })
                sessionStorage.setItem("abriuTelaResposta", true)
            }
            else{
                let element = tabPanelItems.find((item) => {return item.title === itemClicado.itemData.text})// retorna o elemento
                setIndexTabPanelMain(tabPanelItems.indexOf(element))
            } 
        }

        else if (itemClicado.itemData.path !== null && itemClicado.itemData.path === 'ocorrenciaTarefaCustomForm') {

            if(!tabPanelItems.find((item) => {return item.title === itemClicado.itemData.text})){
                setTabPanelItems([
                    ...tabPanelItems,
                    {
                        title: itemClicado.itemData.text
                        , icon: itemClicado.itemData.icon
                        , component:
                            <ScrollView width="100%" height="100%" useNative={true}>
                                <OcorrenciaTarefaCustomForm 
                                    parameters={itemClicado.itemData?.parameters}
                                />
                            </ScrollView>
                        , text: Math.random()
                    }
                ])
                setIndexTabPanelMain(tabPanelItems.length)
            }
            else{
                let element = tabPanelItems.find((item) => {return item.title === itemClicado.itemData.text})// retorna o elemento
                setIndexTabPanelMain(tabPanelItems.indexOf(element))
            } 
        }

        else if (itemClicado.itemData.path !== null && itemClicado.itemData['customRecordForm'] === "historicoForm") {

            if(!tabPanelItems.find((item) => {return item.title === itemClicado.itemData.text})){
                setTabPanelItems([
                    ...tabPanelItems,
                    {
                        title: itemClicado.itemData.text
                        , icon: itemClicado.itemData.icon
                        , component:
                            <ScrollView width="100%" height="100%" useNative={true}>
                                <HistoricoCustomForm
                                    // ocorrencias={props.ocorrencias}
                                />
                            </ScrollView>
                        , text: Math.random()
                    }
                ])
                setIndexTabPanelMain(tabPanelItems.length)
            }
            else{
                let element = tabPanelItems.find((item) => {return item.title === itemClicado.itemData.text})// retorna o elemento
                setIndexTabPanelMain(tabPanelItems.indexOf(element))
            } 
        }

        else if (itemClicado.itemData.path !== null && itemClicado.itemData['customInsertRecordForm'] === "historicoForm") {

            if(!tabPanelItems.find((item) => {return item.title === itemClicado.itemData.text})){
                setTabPanelItems([
                    ...tabPanelItems,
                    {
                        title: itemClicado.itemData.text
                        , icon: itemClicado.itemData.icon
                        , component:
                            <ScrollView width="100%" height="100%" useNative={true}>
                                <HistoricoCustomForm
                                    // ocorrencias={props.ocorrencias}
                                />
                            </ScrollView>
                        , text: Math.random()
                    }
                ])
                setIndexTabPanelMain(tabPanelItems.length)
            }
            else{
                let element = tabPanelItems.find((item) => {return item.title === itemClicado.itemData.text})// retorna o elemento
                setIndexTabPanelMain(tabPanelItems.indexOf(element))
            } 
        }

        else if (itemClicado.itemData.path !== null && itemClicado.itemData.path === 'viewForm') {
            if(!tabPanelItems.find((item) => {return item.title === itemClicado.itemData.text})){
                setTabPanelItems([
                    ...tabPanelItems,
                    {
                        title: itemClicado.itemData.text
                        , icon: itemClicado.itemData.icon
                        , component:
                            <ScrollView width='100%' height='100%' useNative={true}>
                                <ViewForm
                                    id={itemClicado.itemData.parameters}
                                    caption={itemClicado.itemData.text}
                                    telecob={true}
                                />
                            </ScrollView>
                        , text: Math.random()
                    }
                ])
                setIndexTabPanelMain(tabPanelItems.length)
            }
            else{
                let element = tabPanelItems.find((item) => {return item.title === itemClicado.itemData.text})// retorna o elemento
                setIndexTabPanelMain(tabPanelItems.indexOf(element))
            }
            
        }
        else if (itemClicado.itemData.criadoNoGetTelecobrancaViews === true) {  
            sessionStorage.setItem("idTabSelecionada", telecobTabPanelItemsGlobalState.length)
            setTelecobTabPanelItemsGlobalState([...telecobTabPanelItemsGlobalState,
                {
                    title: itemClicado.itemData.text
                    , icon: itemClicado.itemData.icon
                    , id : telecobTabPanelItemsGlobalState.length
                    , component:
                        <ScrollView width='100%' height='100%' useNative={true}>
                            <ViewForm
                                id={itemClicado.itemData.parameters}
                                caption={itemClicado.itemData.text}
                                telecob={true}
                                idTab={telecobTabPanelItemsGlobalState.length}
                                abrirFicha={abrirFicha}
                                // relationid={relationId}

                            // id={masterViewId}//id={item.data[`${viewFieldName}`]}
                                // viewFieldId={itemClicado.itemData[`${viewFieldName}`]}
                                // viewFieldName={viewFieldName}
                            />
                        </ScrollView>
                    , text: Math.random()
                }
            ])
        }

        if(itemClicado.itemData.path !== null && proximaFichaDados.tituloId !== null
            && proximaFichaDados.tituloId.length > 0
            && itemClicado.itemData.path !== 'telecobrancaForm'){
            //checando se ja existe a tab
            if(tabPanelItems.some((item) => { return item["type"] === itemClicado.itemData.path}) 
            && itemClicado.itemData.path !== "boletoForm" && itemClicado.itemData.path !== "gerarAcordoForm"){
                let element = tabPanelItems.find((item) => {return item["type"] === itemClicado.itemData.path})// retorna o elemento
                setIndexTabPanelMain(tabPanelItems.indexOf(element))//retorna o indice do elemento
            }
            else {
                if(itemClicado.itemData.path === 'recordFormCliente') {

                    httpRequest('POST', requestsApi.getEntityStructure, requestsApi.basicAuth, {
                        entityName: itemClicado.itemData.entityName,
                        applicationId: applicationID
                    })
                    .then((sender) => {
                        const resultGetEntityStructureJson = JSON.parse(sender)
            
                        if(resultGetEntityStructureJson?.[0]?.lookUpEntities?.length > 0){
                            let entidadesLookups = resultGetEntityStructureJson?.[0]?.lookUpEntities
                            
                            if(sessionStorage.getItem("lookupsEntities")){
                                const lookupsEntitiesJson = JSON.parse(sessionStorage.getItem("lookupsEntities"))
                                let temLookupNova = false
                                let lookupsNovas = []

                                for(let c = 0; c <= entidadesLookups.length - 1; c++){
                                    if(!lookupsEntitiesJson.find(e => e?.lookUpEntity === entidadesLookups[c]?.lookUpEntity)){
                                        console.log(entidadesLookups[c])
                                        lookupsEntitiesJson.push(entidadesLookups[c])
                                        temLookupNova = true
                                        lookupsNovas.push(entidadesLookups[c])
                                    }
                                }

                                if(temLookupNova){
                                    sessionStorage.setItem("lookupsEntities", JSON.stringify(lookupsEntitiesJson))
                                       
                                    httpRequest("POST", requestsApi.getLookupTable, requestsApi.basicAuth, {
                                        "lookUpEntities": lookupsNovas
                                    })
                                    .then((sender) => {
                                        const senderDataLookup = JSON.parse(sender)
                                        const dataLookupsJson = JSON.parse(sessionStorage.getItem("dataLookups"))[0]

                                        const arrayChavesSenderDataLookup = Object.keys(senderDataLookup)

                                        for(let c = 0; c <= arrayChavesSenderDataLookup.length - 1; c++){
                                            dataLookupsJson[arrayChavesSenderDataLookup[c]] = senderDataLookup[arrayChavesSenderDataLookup[c]]
                                        }

                                        sessionStorage.setItem("dataLookups", JSON.stringify([dataLookupsJson]))
        
                                        setTabPanelItems([
                                            ...tabPanelItems,
                                            {
                                                    title: itemClicado.itemData.text
                                                ,icon: itemClicado.itemData.icon
                                                ,component: <ScrollView  width='100%' height='100%' useNative={true}>
                                                                <RecordForm 
                                                                    id={Math.random()}
                                                                    entityName={itemClicado.itemData.entityName}
                                                                    idValue={proximaFichaDados.clienteId}
                                                                    teleCobranca={true}
                                                                    entityStructure={resultGetEntityStructureJson}
                                                                    dataLookup={dataLookupsJson}
                                                                />
                                                            </ScrollView>
                                                ,text: Math.random()
                                                ,type: itemClicado.itemData.path
                                            }
                                        ])
                                        setIndexTabPanelMain(tabPanelItems.length)
                    
                                    })
                                    .catch((error) => console.log(error))
                                }
                                else if(sessionStorage.getItem("dataLookups")){
                                    setTabPanelItems([
                                        ...tabPanelItems,
                                        {
                                             title: itemClicado.itemData.text
                                            ,icon: itemClicado.itemData.icon
                                            ,component: <ScrollView  width='100%' height='100%' useNative={true}>
                                                            <RecordForm 
                                                                id={Math.random()}
                                                                entityName={itemClicado.itemData.entityName}
                                                                idValue={proximaFichaDados.clienteId}
                                                                teleCobranca={true}
                                                                entityStructure={resultGetEntityStructureJson}
                                                                dataLookup={JSON.parse(sessionStorage.getItem("dataLookups"))[0]}
                                                            />
                                                        </ScrollView>
                                            ,text: Math.random()
                                            ,type: itemClicado.itemData.path
                                        }
                                    ])
                                    setIndexTabPanelMain(tabPanelItems.length)
                                }
                                
                            }
                            else{
                                httpRequest("POST", requestsApi.getLookupTable, requestsApi.basicAuth, {
                                    "lookUpEntities": entidadesLookups
                                })
                                .then((sender) => {
                                    const senderDataLookup = JSON.parse(sender)
                                    sessionStorage.setItem("lookupsEntities", JSON.stringify(entidadesLookups))
                                    sessionStorage.setItem("dataLookups", JSON.stringify([senderDataLookup]))
    
                                    setTabPanelItems([
                                        ...tabPanelItems,
                                        {
                                                title: itemClicado.itemData.text
                                            ,icon: itemClicado.itemData.icon
                                            ,component: <ScrollView  width='100%' height='100%' useNative={true}>
                                                            <RecordForm 
                                                                id={Math.random()}
                                                                entityName={itemClicado.itemData.entityName}
                                                                idValue={proximaFichaDados.clienteId}
                                                                teleCobranca={true}
                                                                entityStructure={resultGetEntityStructureJson}
                                                                dataLookup={senderDataLookup}
                                                            />
                                                        </ScrollView>
                                            ,text: Math.random()
                                            ,type: itemClicado.itemData.path
                                        }
                                    ])
                                    setIndexTabPanelMain(tabPanelItems.length)
                
                                })
                                .catch((error) => console.log(error))
                            }
                        }
                        else{
                            setTabPanelItems([
                                ...tabPanelItems,
                                {
                                     title: itemClicado.itemData.text
                                    ,icon: itemClicado.itemData.icon
                                    ,component: 
                                                <ScrollView  width='100%' height='100%' useNative={true}>
                                                    <RecordForm 
                                                        id={Math.random()} 
                                                        entityName={itemClicado.itemData.entityName}
                                                        idValue={proximaFichaDados.clienteId}
                                                        teleCobranca={true}
                                                        entityStructure={resultGetEntityStructureJson}
                                                    />
                                                </ScrollView>
                                    ,text: Math.random()
                                    ,type: itemClicado.itemData.path
                                }
                            ])
                            setIndexTabPanelMain(tabPanelItems.length)
                        } 
                    })
                    .catch((error) => console.log(error))
                }
                else if (itemClicado.itemData.path === 'recordFormContrato') {

                    httpRequest('POST', requestsApi.getEntityStructure, requestsApi.basicAuth, {
                        entityName: itemClicado.itemData.entityName,
                        applicationId: applicationID
                    })
                    .then((sender) => {
                        const resultGetEntityStructureJson = JSON.parse(sender)
            
                        if(resultGetEntityStructureJson?.[0]?.lookUpEntities?.length > 0){
                            let entidadesLookups = resultGetEntityStructureJson?.[0]?.lookUpEntities
                            
                            if(sessionStorage.getItem("lookupsEntities")){
                                const lookupsEntitiesJson = JSON.parse(sessionStorage.getItem("lookupsEntities"))
                                let temLookupNova = false
                                let lookupsNovas = []

                                for(let c = 0; c <= entidadesLookups.length - 1; c++){
                                    if(!lookupsEntitiesJson.find(e => e?.lookUpEntity === entidadesLookups[c]?.lookUpEntity)){
                                        console.log(entidadesLookups[c])
                                        lookupsEntitiesJson.push(entidadesLookups[c])
                                        temLookupNova = true
                                        lookupsNovas.push(entidadesLookups[c])
                                    }
                                }

                                if(temLookupNova){
                                    sessionStorage.setItem("lookupsEntities", JSON.stringify(lookupsEntitiesJson))
                                       
                                    httpRequest("POST", requestsApi.getLookupTable, requestsApi.basicAuth, {
                                        "lookUpEntities": lookupsNovas
                                    })
                                    .then((sender) => {
                                        const senderDataLookup = JSON.parse(sender)
                                        const dataLookupsJson = JSON.parse(sessionStorage.getItem("dataLookups"))[0]

                                        const arrayChavesSenderDataLookup = Object.keys(senderDataLookup)

                                        for(let c = 0; c <= arrayChavesSenderDataLookup.length - 1; c++){
                                            dataLookupsJson[arrayChavesSenderDataLookup[c]] = senderDataLookup[arrayChavesSenderDataLookup[c]]
                                        }

                                        sessionStorage.setItem("dataLookups", JSON.stringify([dataLookupsJson]))
        
                                        setTabPanelItems([
                                            ...tabPanelItems,
                                            {
                                                    title: itemClicado.itemData.text
                                                ,icon: itemClicado.itemData.icon
                                                ,component: <ScrollView  width='100%' height='100%' useNative={true}>
                                                                <RecordForm 
                                                                    id={Math.random()}
                                                                    entityName={itemClicado.itemData.entityName}
                                                                    idValue={proximaFichaDados.tituloId}
                                                                    teleCobranca={true}
                                                                    entityStructure={resultGetEntityStructureJson}
                                                                    dataLookup={dataLookupsJson}
                                                                />
                                                            </ScrollView>
                                                ,text: Math.random()
                                                ,type: itemClicado.itemData.path
                                            }
                                        ])
                                        setIndexTabPanelMain(tabPanelItems.length)
                    
                                    })
                                    .catch((error) => console.log(error))
                                }
                                else if(sessionStorage.getItem("dataLookups")){
                                    setTabPanelItems([
                                        ...tabPanelItems,
                                        {
                                             title: itemClicado.itemData.text
                                            ,icon: itemClicado.itemData.icon
                                            ,component: <ScrollView  width='100%' height='100%' useNative={true}>
                                                            <RecordForm 
                                                                id={Math.random()}
                                                                entityName={itemClicado.itemData.entityName}
                                                                idValue={proximaFichaDados.tituloId}
                                                                teleCobranca={true}
                                                                entityStructure={resultGetEntityStructureJson}
                                                                dataLookup={JSON.parse(sessionStorage.getItem("dataLookups"))[0]}
                                                            />
                                                        </ScrollView>
                                            ,text: Math.random()
                                            ,type: itemClicado.itemData.path
                                        }
                                    ])
                                    setIndexTabPanelMain(tabPanelItems.length)
                                }
                                
                            }
                            else{
                                httpRequest("POST", requestsApi.getLookupTable, requestsApi.basicAuth, {
                                    "lookUpEntities": entidadesLookups
                                })
                                .then((sender) => {
                                    const senderDataLookup = JSON.parse(sender)
                                    sessionStorage.setItem("lookupsEntities", JSON.stringify(entidadesLookups))
                                    sessionStorage.setItem("dataLookups", JSON.stringify([senderDataLookup]))
    
                                    setTabPanelItems([
                                        ...tabPanelItems,
                                        {
                                                title: itemClicado.itemData.text
                                            ,icon: itemClicado.itemData.icon
                                            ,component: <ScrollView  width='100%' height='100%' useNative={true}>
                                                            <RecordForm 
                                                                id={Math.random()}
                                                                entityName={itemClicado.itemData.entityName}
                                                                idValue={proximaFichaDados.tituloId}
                                                                teleCobranca={true}
                                                                entityStructure={resultGetEntityStructureJson}
                                                                dataLookup={senderDataLookup}
                                                            />
                                                        </ScrollView>
                                            ,text: Math.random()
                                            ,type: itemClicado.itemData.path
                                        }
                                    ])
                                    setIndexTabPanelMain(tabPanelItems.length)
                
                                })
                                .catch((error) => console.log(error))
                            }
                        }
                        else{
                            setTabPanelItems([
                                ...tabPanelItems,
                                {
                                     title: itemClicado.itemData.text
                                    ,icon: itemClicado.itemData.icon
                                    ,component: <ScrollView  width='100%' height='100%' useNative={true}>
                                                    <RecordForm 
                                                        id={Math.random()}
                                                        entityName={itemClicado.itemData.entityName}
                                                        idValue={proximaFichaDados.tituloId}
                                                        teleCobranca={true}
                                                        entityStructure={resultGetEntityStructureJson}
                                                    />
                                                </ScrollView>
                                    ,text: Math.random()
                                    ,type: itemClicado.itemData.path
                                }
                            ])
                            setIndexTabPanelMain(tabPanelItems.length)
                        } 
                    })
                    .catch((error) => console.log(error))
                }
                else if (itemClicado.itemData.path === 'gerarAcordoForm') {
                    // setPopup({
                    //     ...popup
                    //         ,isVisible: true
                    //         ,message: "Atenção" 
                    // })
                    setTabPanelItems([
                        ...tabPanelItems,
                        {
                             title: itemClicado.itemData.text
                            ,icon: itemClicado.itemData.icon
                            ,component: <ScrollView  width='100%' height='100%' useNative={true}>
                                            <GerarAcordoForm
                                                tituloId={proximaFichaDados.tituloId}
                                                credorId={proximaFichaDados.credorId}
                                                clienteId={proximaFichaDados.clienteId}
                                                showTitle={true}
                                            />
                                        </ScrollView>
                            ,text: Math.random()
                            ,type: itemClicado.itemData.path
                        }
                    ])
                    setIndexTabPanelMain(tabPanelItems.length)
                }
                else if (itemClicado.itemData.path === 'boletoForm') {
                    setTabPanelItems([
                        ...tabPanelItems,
                        {
                             title: itemClicado.itemData.text
                            ,icon: itemClicado.itemData.icon
                            ,component: <ScrollView  width='100%' height='100%' useNative={true}>
                                            <GerarBoletoForm
                                                tituloId={proximaFichaDados.tituloId}
                                                credorId={proximaFichaDados.credorId}
                                                clienteId={proximaFichaDados.clienteId}
                                                showTitle={true}
                                                abertoMenuTelecob={true}
                                            />
                                        </ScrollView>
                            ,text: Math.random()
                            ,type: itemClicado.itemData.path
                        }
                    ])
                    setIndexTabPanelMain(tabPanelItems.length)
                }
            }
        }
        if(itemClicado.itemData.path === 'pausa') {

            if(discadorEmAtendimentoGlobalState){
                //colocar estado global para pedir pausa depois de gravar ocorrencia
                setPausaEscolhidaDadosGlobalState({
                    applicationId: applicationID,
                    relationId: relationId,
                    tipoPausaId: itemClicado.itemData.id,
                    tipoPausaCodigo: itemClicado.itemData._codigo,
                    ramal: ramal,
                    host: backendJson.api_host,
                    porta: backendJson.api_port  
                })
                setPopup({
                    ...popup
                    ,isVisible: true
                    ,message: "Pausa solicitada! Quando a ocorrência for gravada a solicitação será concluida." 
                })
            }
            else{
                httpRequest('POST', requestsApi.solicitarPausa, requestsApi.basicAuth, {
                    applicationId: applicationID,
                    relationId: relationId,
                    tipoPausaId: itemClicado.itemData.id,
                    tipoPausaCodigo: itemClicado.itemData._codigo,
                    ramal: ramal,
                    host: backendJson.api_host,
                    porta: backendJson.api_port   
                })
                .then((sender) => {
                    const resultSolicitarPausa = JSON.parse(sender)
                    toastConfiguration(true, "success", resultSolicitarPausa.mensagem)
                })
                .catch((err) => {
                    console.log(err)
                    setPopupErrorMessageState(err?.data?.err)
                    setPopupErrorVisibleState(true)
                })
            }
        }

        if (itemClicado.itemData.path === "outrosContratosForm") {
            setItemClicadoState(itemClicado);
            setPopupQueryFormOpen(true);

            getClientContracts()
                .then((dataSource) => {
                    setOutrosContratosDataSource(dataSource);                    
                })
        }
    }, [
        setIndexTabPanelMain, 
        setTabPanelItems, 
        tabPanelItems, 
        indexTabPanelMain, 
        pausaEscolhidaDadosGlobalState, 
        discadorEmAtendimentoGlobalState,
        proximaFichaDados,
        mainMenuJson,
        pausasDisponiveisGlobalState
    ])

    // const rightTabPanelVisibleFunction = () => {
    //     setStates(
    //       {
    //         ...states, 
    //         rightTabPanelVisible: !states.rightTabPanelVisible,
    //         iconButtonRightPanel: states.rightTabPanelVisible ? "chevrondoubleleft" : "chevrondoubleright"
    //       })
    // }

    // const widgetsOptions = {
    //   icon: states.iconButtonRightPanel
    //   ,hoverStateEnabled: true
    //   ,height: 35
    //   ,stylingMode: "text"
    //   ,onClick: () => {rightTabPanelVisibleFunction()}
    // }


    return (
        <div id="div-telecob-main">

            {popupQueryFormOpen && (
                <QueryFormPopup 
                    id={Math.random()}
                    entityName={itemClicadoState && itemClicadoState.entityName}                                
                    tituloQueryFormPopup={itemClicadoState && itemClicadoState.text}
                    telecob={true}
                    abrirFicha={abrirFicha}                                
                    foreignFieldSelectedItem={foreignFieldSelectedItem}
                    setForeignFieldSelectedItem={setForeignFieldSelectedItem}
                    setQueryFormPopupForeignFieldState={setQueryFormPopupForeignFieldState}
                    queryFormPopupForeignFieldState={queryFormPopupForeignFieldState}
                    setQueryFormPopupState={setQueryFormPopupState}
                    popupQueryFormOpen={popupQueryFormOpen}
                    setPopupQueryFormOpen={setPopupQueryFormOpen}
                    queryFormPopupState={queryFormPopupState}
                    queryPopupType="outrosContratos"
                    outrosContratosDataSource={outrosContratosDataSource}
                />
            )}

            <UpfPopupMessage
                hidePopup={hidePopupError}
                visible={popupErrorVisibleState}
                message={popupErrorMessageState}
            />

            <Popup
                visible={popup.isVisible}
                onHiding={hidePopup}
                dragEnabled={false}
                hideOnOutsideClick={true}
                showCloseButton={true}
                showTitle={true}
                title="Atenção"
                width={300}
                height={280}
                resizeEnabled={true}
            >
                <h5>{popup.message}</h5>
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={closeButtonPopup}
                />
            </Popup>

            <Toast
                visible={statesToast.toastVisible}
                message={statesToast.toastMessage}
                type={statesToast.toastType}
                onHiding={onHidingToast}
                displayTime={1800}
                height={60}
            />
            
            <div>
            <DrawerVariant
                component={
                    <TabPanel
                        id="tabpanel-main"
                        items={telecobTabPanelItemsGlobalState} 
                        deferRendering={false}
                        itemRender={contentTab}
                        selectedIndex={telecobIndexTabPanelGlobalState}
                        onTitleRendered={(item) => {
                            setTelecobIndexTabPanelGlobalState(telecobTabPanelItemsGlobalState.length)
                        }}
                        onSelectionChanged={(item) => {
                            console.log(telecobTabPanelItemsGlobalState.indexOf(item.addedItems[0]))
                            sessionStorage.setItem("idTabSelecionada", telecobTabPanelItemsGlobalState.indexOf(item.addedItems[0]))
                            setTelecobIndexTabPanelGlobalState(telecobTabPanelItemsGlobalState.indexOf(item.addedItems[0]))
                        }}
                        repaintChangesOnly={true}
                        animationEnabled={false}
                        showNavButtons={true}
                        scrollByContent={true}
                        itemTitleRender={(sender) => titleTabPanel(sender)}
                    />
                }
                telecobMenu={mainMenuJson}
                addNewTabTelecob={addNewTab}
                optionChange={optionChangeMultiViewMainTeleCob} 
                setIndexTabPanelMain={setIndexTabPanelMain}
                getServerInfoState={props.dataSourceLogin}
                getDashboardInfo={props.dataSourceLoginDashboard}
            />
            </div>
    
            <div>
            {rightTabPanelVisible &&
                <>
                    <RightTabPanelTeleCob
                        setParamsProcessamentoMensagemSocketState={props.setParamsProcessamentoMensagemSocketState}
                        paramsProcessamentoMensagemSocket={props.paramsProcessamentoMensagemSocket}
                    />
                    <a  
                        id="URLLogin" 
                        target="_blank" 
                        rel="noreferrer" 
                        href={`${URLLogin}`}
                        
                    > </a>
                </>
            }
            </div>
        
        </div>
    )
}

export default memo(TeleCob);