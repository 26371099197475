export const alterarCorDestaqueTema = (sender) => {
    console.log(sender)
    switch (sender) {
        case "greendark":
            document.querySelector(':root').style.setProperty('--cor-destaque-tema', "rgb(205, 220, 57)")
            document.querySelector(':root').style.setProperty('--bloquear-transparencia-fundo', '0');

            break;
        
        case "darkblue":
            document.querySelector(':root').style.setProperty('--cor-destaque-tema', "rgb(3, 169, 244)")
            document.querySelector(':root').style.setProperty('--bloquear-transparencia-fundo', '1');

            break;

        case "softblue":
            document.querySelector(':root').style.setProperty('--cor-destaque-tema', "rgb(3, 169, 244)")
            document.querySelector(':root').style.setProperty('--bloquear-transparencia-fundo', '1');

            break;

        case "greenmist":
            document.querySelector(':root').style.setProperty('--cor-destaque-tema', "rgb(0, 150, 136)")
            document.querySelector(':root').style.setProperty('--bloquear-transparencia-fundo', '0');

            break;

        case "darkviolet":
            document.querySelector(':root').style.setProperty('--cor-destaque-tema', "rgb(156, 39, 176)")
            document.querySelector(':root').style.setProperty('--bloquear-transparencia-fundo', '0');

            break;

        case "carmine":
            document.querySelector(':root').style.setProperty('--cor-destaque-tema', "rgb(255, 87, 34)")
            document.querySelector(':root').style.setProperty('--bloquear-transparencia-fundo', '0');

            break;

        case "darkmoon":
            document.querySelector(':root').style.setProperty('--cor-destaque-tema', "rgb(0, 150, 136)")
            document.querySelector(':root').style.setProperty('--bloquear-transparencia-fundo', '0');

            break;
    
        default:
            break;
    }
}